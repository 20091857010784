<template>
  <div class="node-test">
    <el-tabs v-model="activeTab" class="test-tabs">
      <el-tab-pane label="站点测试" name="siteTest">
        <el-card class="test-form" :body-style="{ padding: '12px' }">
          <el-form :model="form" :rules="rules" ref="form" label-width="70px">
            <!-- 第一行 -->
            <div class="form-row">
              <el-form-item label="节点" prop="region">
                <el-select v-model="form.region" placeholder="请选择服务节点">
                  <el-option
                    v-for="item in regions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="站点" prop="site">
                <el-select 
                  v-model="form.site" 
                  placeholder="请选择站点"
                  filterable
                  clearable>
                  <el-option
                    v-for="site in sites"
                    :key="site.value"
                    :label="site.label"
                    :value="site.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="SID" prop="sid">
                <el-input v-model="form.sid" placeholder="请输入SID"></el-input>
              </el-form-item>
            </div>

            <!-- 第二行 -->
            <div class="form-row">
              <el-form-item label="文章ID" prop="articleId">
                <el-input v-model="form.articleId" placeholder="请输入文章ID"></el-input>
              </el-form-item>

              <el-form-item label="Terms" prop="terms">
                <el-input v-model="form.terms" placeholder="请输入terms"></el-input>
              </el-form-item>
            </div>

            <!-- 第三行 -->
            <div class="form-row">
              <el-form-item label="RAC" prop="rac">
                <el-input v-model="form.rac" placeholder="请输入rac"></el-input>
              </el-form-item>

              <el-form-item label="Channel" prop="channel">
                <el-input v-model="form.channel" placeholder="请输入channel"></el-input>
              </el-form-item>
            </div>

            <!-- 第四行 -->
            <div class="form-row">
              <el-form-item label="重试数" prop="maxRetries">
                <el-input-number 
                  v-model="form.maxRetries" 
                  :min="1" 
                  :max="10"
                  placeholder="重试次数">
                </el-input-number>
              </el-form-item>

              <el-form-item label="超时" prop="maxWaitTime">
                <el-input-number 
                  v-model="form.maxWaitTime" 
                  :min="1000" 
                  :max="60000"
                  :step="1000"
                  placeholder="超时(ms)">
                </el-input-number>
              </el-form-item>
            </div>

            <!-- 按钮行 -->
            <div class="form-actions">
              <el-button type="primary" size="mini" @click="handleTest" :loading="loading">开始测试</el-button>
              <el-button size="mini" @click="resetForm">重置</el-button>
            </div>
          </el-form>
        </el-card>

        <!-- 测试结果展示区域 -->
        <el-card class="test-result" v-if="testResult">
          <div slot="header">
            <span>测试结果</span>
          </div>

          <!-- 基本信息 -->
          <el-descriptions :column="1" border size="small" class="basic-info">
            <template slot="title">
              <i class="el-icon-info" style="margin-right: 4px;"></i>基本信息
            </template>
            <el-descriptions-item label="访问URL">
              <el-link type="primary" :href="testResult.data.url" target="_blank">
                {{ testResult.data.url }}
              </el-link>
            </el-descriptions-item>
            <el-descriptions-item label="访问时间">
              {{ new Date(testResult.data.timestamp).toLocaleString() }}
            </el-descriptions-item>
            <el-descriptions-item label="加载时间">
              <span style="color: #67C23A;">
                {{ testResult.data.pageLoadTime }}s
              </span>
            </el-descriptions-item>
          </el-descriptions>

          <!-- 容器信息 -->
          <div v-for="container in testResult.data.containers" :key="container.containerIndex" class="container-section">
            <div class="container-header">容器 {{ container.containerIndex }} 相关搜索关键词</div>
            
            <!-- 链接列表 -->
            <div class="links-container">
              <div v-for="(link, index) in container.links" 
                   :key="index" 
                   class="link-item">
                {{ link.text }}
              </div>
            </div>

            <!-- 着陆页信息 -->
            <div v-if="container.landingPages && container.landingPages.length">
              <div class="section-title">搜索结果页面</div>
              <el-collapse>
                <el-collapse-item v-for="(page, index) in container.landingPages" 
                                :key="index" 
                                class="custom-collapse-item">
                  <template slot="title">
                    <i :class="page.screenshots && page.screenshots.length ? 'el-icon-check success-icon' : 'el-icon-close error-icon'"></i>
                    <span>搜索结果 {{ index + 1 }} - {{ page.text }}</span>
                  </template>
                  <el-descriptions :column="2" border>
                    <el-descriptions-item label="URL">
                      <el-link type="primary" :href="page.url" target="_blank">{{ page.url }}</el-link>
                    </el-descriptions-item>
                    <el-descriptions-item label="加载时间">
                      <div style="color: #67C23A;width: 40px">
                        {{ page.pageLoadTime }}s
                      </div>
                    </el-descriptions-item>
                  </el-descriptions>
                  
                  <!-- 截图展示 -->
                  <div v-if="page.screenshots && page.screenshots.length" class="screenshots">
                    <h5>页面截图</h5>
                    <el-image 
                      v-for="(screenshot, idx) in page.screenshots"
                      :key="idx"
                      :src="`data:image/png;base64,${screenshot.base64}`"
                      :preview-src-list="[`data:image/png;base64,${screenshot.base64}`]"
                      fit="contain"
                      class="screenshot-image"
                    />
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </el-card>
      </el-tab-pane>

      <el-tab-pane label="竞品分析" name="competitorAnalysis">
        <el-card class="competitor-analysis">
          <el-form :model="competitorForm" :rules="competitorRules" ref="competitorForm" label-width="70px">
            <div class="form-row">
              <el-form-item label="节点" prop="region">
                <el-select v-model="competitorForm.region" placeholder="请选择服务节点">
                  <el-option
                    v-for="item in regions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              
              <el-form-item label="站点" prop="url">
                <el-input v-model="competitorForm.url" placeholder="请输入站点"></el-input>
              </el-form-item>
            </div>

            <div class="form-row">
              <el-form-item label="重试数" prop="maxRetries">
                <el-input-number 
                  v-model="competitorForm.maxRetries" 
                  :min="1" 
                  :max="10"
                  placeholder="重试次数">
                </el-input-number>
              </el-form-item>

              <el-form-item label="超时" prop="maxWaitTime">
                <el-input-number 
                  v-model="competitorForm.maxWaitTime" 
                  :min="1000" 
                  :max="60000"
                  :step="1000"
                  placeholder="超时(ms)">
                </el-input-number>
              </el-form-item>
            </div>

            <div class="form-actions">
              <el-button type="primary" size="mini" @click="handleCompetitorAnalysis" :loading="competitorLoading">开始分析</el-button>
              <el-button size="mini" @click="resetCompetitorForm">重置</el-button>
            </div>
          </el-form>
        </el-card>

        <!-- 添加分析结果展示区域 -->
        <el-card class="test-result" v-if="competitorResult">
          <div slot="header">
            <span>分析结果</span>
          </div>

          <!-- 基本信息 -->
          <el-descriptions :column="1" border size="small" class="basic-info">
            <template slot="title">
              <i class="el-icon-info" style="margin-right: 4px;"></i>基本信息
            </template>
            <el-descriptions-item label="访问URL">
              <el-link type="primary" :href="competitorResult.data.url" target="_blank">
                {{ competitorResult.data.url }}
              </el-link>
            </el-descriptions-item>
            <el-descriptions-item label="访问时间">
              {{ new Date(competitorResult.data.timestamp).toLocaleString() }}
            </el-descriptions-item>
            <el-descriptions-item label="加载时间">
              <span style="color: #67C23A;">
                {{ competitorResult.data.pageLoadTime }}s
              </span>
            </el-descriptions-item>
          </el-descriptions>

          <!-- 容器信息 -->
          <div v-for="container in competitorResult.data.containers" :key="container.containerIndex" class="container-section">
            <div class="container-header">容器 {{ container.containerIndex }} 相关搜索关键词</div>
            
            <!-- URL分析信息 -->
            <div v-if="container.urlAnalyses && container.urlAnalyses.length" class="url-analysis">
              <div class="analysis-item">
                <div class="section-title">搜索关键词</div>
                <el-tag 
                  v-if="container.urlAnalyses[0].comparisons[0].kw"
                  size="mini"
                  type="success"
                  effect="plain"
                  class="analysis-tag">
                  {{ container.urlAnalyses[0].comparisons[0].kw }}
                </el-tag>
              </div>

              <div class="analysis-item">
                <div class="section-title">相关词条</div>
                <el-tag
                  v-for="(term, index) in container.urlAnalyses[0].comparisons[0].terms.split(',')"
                  :key="`${term}-${index}`"
                  size="mini"
                  type="info"
                  effect="plain"
                  class="analysis-tag">
                  {{ term }}
                </el-tag>
              </div>

              <div class="analysis-item">
                <div class="section-title">自定义参数</div>
                <el-tag
                  v-for="param in container.urlAnalyses[0].comparisons[0].paramDifferences.additional"
                  :key="param"
                  size="mini"
                  type="warning"
                  effect="plain"
                  class="analysis-tag">
                  {{ param }}
                </el-tag>
              </div>
            </div>

            <!-- 链接列表 -->
            <div class="links-container">
              <div v-for="(link, index) in container.links" 
                   :key="index" 
                   class="link-item">
                {{ link.text }}
              </div>
            </div>

            <!-- 着陆页信息 -->
            <div v-if="container.landingPages && container.landingPages.length">
              <div class="section-title">搜索结果页面</div>
              <el-collapse>
                <el-collapse-item v-for="(page, index) in container.landingPages" 
                                :key="index" 
                                class="custom-collapse-item">
                  <template slot="title">
                    <i :class="page.screenshots && page.screenshots.length ? 'el-icon-check success-icon' : 'el-icon-close error-icon'"></i>
                    <span>搜索结果 {{ index + 1 }} - {{ page.text }}</span>
                  </template>

                  <!-- URL分析信息 -->
                  <div v-if="page.urlAnalysis" class="url-analysis">
                    <div class="analysis-item">
                      <div class="section-title">匹配关键词</div>
                      <el-tag
                        v-if="page.text"
                        size="mini"
                        type="success"
                        effect="plain"
                        class="analysis-tag">
                        {{ page.text }}
                      </el-tag>
                    </div>
                  </div>

                  <el-descriptions :column="2" border>
                    <el-descriptions-item label="URL">
                      <el-link type="primary" :href="page.url" target="_blank">{{ page.url }}</el-link>
                    </el-descriptions-item>
                    <el-descriptions-item label="加载时间">
                      <div style="color: #67C23A;width: 40px">
                        {{ page.pageLoadTime }}s
                      </div>
                    </el-descriptions-item>
                  </el-descriptions>
                  
                  <div v-if="page.screenshots && page.screenshots.length" class="screenshots">
                    <h5>页面截图</h5>
                    <el-image 
                      v-for="(screenshot, idx) in page.screenshots"
                      :key="idx"
                      :src="`data:image/png;base64,${screenshot.base64}`"
                      :preview-src-list="[`data:image/png;base64,${screenshot.base64}`]"
                      fit="contain"
                      class="screenshot-image"
                    />
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  export default {
    name: 'NodeTest',
    data() {
      return {
        activeTab: 'siteTest',
        loading: false,
        form: {
          region: 'jp',
          site: '',
          terms: '',
          rac: '',
          sid: '',
          channel: '',
          articleId: '',
          maxRetries: 5,
          maxWaitTime: 15000
        },
        sites: [
          { value: 'jonygamee', label: 'jonytoday' },
          { value: 'lifeguidewise', label: 'lifeguidewise' },
          { value: 'lifeguidewise2', label: 'lifeguidewise2' },
          { value: 'lifeguidewise3', label: 'lifeguidewise3' },
          { value: 'lifeguidewise4', label: 'lifeguidewise4' },
          { value: 'lifeguidewise5', label: 'lifeguidewise5' },
          { value: 'knowshowdaily', label: 'knowshowdaily' },
          { value: 'smartlifeguide', label: 'smartlifeguide' },
          { value: 'smartlifecompass', label: 'smartlifecompass' },
          { value: 'convenientguide', label: 'convenientguide' },
          { value: 'smartgiudewise', label: 'smartgiudewise' },
          { value: 'knowslife', label: 'knowslife' },
          { value: 'monoguides', label: 'monoguides' },
          { value: 'lifeguidebible', label: 'lifeguidebible' },
          { value: 'lifenotesguide', label: 'lifenotesguide' },
          { value: 'dailylifesinfo', label: 'dailylifesinfo' },
          { value: 'globallifepedia', label: 'globallifepedia' },
          { value: 'ulifeguide', label: 'ulifeguide' },
          { value: 'ulivehacks', label: 'ulivehacks' },
          { value: 'globaldaylife', label: 'globaldaylife' },
          { value: 'globallifestylehub', label: 'globallifestylehub' },
          { value: 'ulifestyleguide', label: 'ulifestyleguide' },
          { value: 'worldlylifehacks', label: 'worldlylifehacks' },
          { value: 'smartguidelife', label: 'smartguidelife' },
          { value: 'guidesfyou', label: 'guidesfyou' },
          { value: 'trustedlifestyles', label: 'trustedlifestyles' },
          { value: 'alivingguide', label: 'alivingguide' },
          { value: '1expertadvice', label: '1expertadvice' },
          { value: 'knowhowdaily', label: 'knowhowdaily' },
          { value: 'playallure', label: 'playallure' },
        ],
        rules: {
          region: [
            { required: true, message: '请选择服务节点', trigger: 'change' }
          ],
          site: [
            { required: true, message: '请选择站点', trigger: 'change' }
          ],
          terms: [],
          rac: [],
          sid: [],
          channel: [],
          articleId: [
            { required: true, message: '请输入文章ID', trigger: 'blur' }
          ],
          maxRetries: [
            { required: true, message: '请输入重试次数', trigger: 'blur' },
            { type: 'number', min: 1, max: 10, message: '重试次数必须在1-10之间', trigger: 'blur' }
          ],
          maxWaitTime: [
            { required: true, message: '请输入超时时间', trigger: 'blur' },
            { type: 'number', min: 1000, max: 60000, message: '超时时间必须在1000-60000毫秒之间', trigger: 'blur' }
          ]
        },
        testResult: null,
        competitorForm: {
          region: 'jp',
          url: '',
          maxRetries: 5,
          maxWaitTime: 15000
        },
        competitorRules: {
          region: [
            { required: true, message: '请选择服务节点', trigger: 'change' }
          ],
          site: [
            { required: true, message: '请输入站点', trigger: 'blur' }
          ],
          maxRetries: [
            { required: true, message: '请输入重试次数', trigger: 'blur' },
            { type: 'number', min: 1, max: 10, message: '重试次数必须在1-10之间', trigger: 'blur' }
          ],
          maxWaitTime: [
            { required: true, message: '请输入超时时间', trigger: 'blur' },
            { type: 'number', min: 1000, max: 60000, message: '超时时间必须在1000-60000毫秒之间', trigger: 'blur' }
          ]
        },
        competitorLoading: false,
        competitorResult: null,
        regions: [
          { value: 'jp', label: '日本' },
          { value: 'us', label: '美国' },
          { value: 'bx', label: '巴西' },
        ]
      }
    },
    methods: {
      async handleTest() {
        try {
          await this.$refs.form.validate()
          this.testResult = null
          this.loading = true
          const response = await this.$http.post(`/${this.form.region}/node/visit`, this.form, {
            timeout: 100000000
          })
          this.testResult = response.data
          this.$message.success('测试完成')
        } catch (error) {
          if (error.message) {
            this.$message.error(error.message)
          }
        } finally {
          this.loading = false
        }
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.testResult = null
      },
      async handleCompetitorAnalysis() {
        try {
          await this.$refs.competitorForm.validate()
          this.competitorResult = null
          this.competitorLoading = true
          const response = await this.$http.post(`/${this.competitorForm.region}/node/visit`, this.competitorForm, {
            timeout: 100000000
          })
          this.competitorResult = response.data
          this.$message.success('分析完成')
        } catch (error) {
          if (error.message) {
            this.$message.error(error.message)
          }
        } finally {
          this.competitorLoading = false
        }
      },
      resetCompetitorForm() {
        this.$refs.competitorForm.resetFields()
        this.competitorResult = null
      }
    }
  }
</script>

<style scoped>
  .node-test {
    height: 100%;
    padding: 8px;
  }

  .test-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .test-tabs :deep(.el-tabs__content) {
    flex: 1;
    overflow: auto;
  }

  .competitor-analysis {
    height: 100%;
  }

  .empty-content {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #909399;
    font-size: 14px;
  }

  .test-form {
    margin-bottom: 12px;
  }

  .form-row {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
  }

  .form-row :deep(.el-form-item) {
    flex: 1;
    margin-bottom: 0;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }

  .form-actions :deep(.el-button) {
    padding: 8px 20px;
  }

  /* 调整输入框和下拉框的高度 */
  :deep(.el-input__inner) {
    height: 32px;
    line-height: 32px;
  }

  /* 调整表单项标签样式 */
  :deep(.el-form-item__label) {
    line-height: 32px;
    padding: 0 12px 0 0;
  }

  :deep(.el-form-item__content) {
    line-height: 32px;
  }

  /* 调整数字输入框样式 */
  :deep(.el-input-number) {
    width: 100%;
    line-height: 32px;
  }

  :deep(.el-input-number__decrease),
  :deep(.el-input-number__increase) {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .test-result {
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .container-section {
    margin: 8px 0;
    background: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 3px;
  }

  .container-header {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 500;
    color: #303133;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e4e7ed;
  }

  .links-container {
    padding: 6px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 4px;
    background: #fff;
  }

  .link-item {
    padding: 4px 8px;
    background: #f5f7fa;
    border-radius: 2px;
    color: #606266;
    font-size: 12px;
    line-height: 1.2;
    border: 1px solid #ebeef5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-title {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 500;
    color: #303133;
    border-top: 1px solid #e4e7ed;
    background-color: #f5f7fa;
  }

  .screenshots {
    margin-top: 4px;
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  .screenshot-image {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    border: 1px solid #e4e7ed;
    transition: all 0.2s;
  }

  .screenshot-image:hover {
    transform: scale(1.01);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }

  .custom-collapse-item :deep(.el-collapse-item__content) {
    padding: 6px;
  }

  .custom-collapse-item :deep(.el-collapse-item__header) {
    padding: 0 8px;
    font-size: 12px;
    height: 32px;
    background-color: #fafafa;
  }

  .test-result :deep(.el-descriptions) {
    padding: 4px 0;
    margin-bottom: 6px;
  }

  .test-result :deep(.el-descriptions__header) {
    margin-bottom: 4px;
  }

  .test-result :deep(.el-descriptions__title) {
    font-size: 12px;
    color: #303133;
    font-weight: 500;
  }

  .test-result :deep(.el-descriptions-item__label) {
    width: 70px;
    color: #606266;
    font-size: 12px;
    padding: 4px 8px;
    background-color: #fafafa;
  }

  .test-result :deep(.el-descriptions-item__content) {
    padding: 4px 8px;
    font-size: 12px;
  }

  .test-result :deep(.el-link) {
    font-size: 12px;
  }

  .success-icon {
    color: #67C23A;
    margin-right: 4px;
    font-size: 13px;
  }

  .error-icon {
    color: #F56C6C;
    margin-right: 4px;
    font-size: 13px;
  }

  .url-analysis {
    padding: 12px;
    background: #f8f9fa;
    border-radius: 4px;
  }

  .analysis-item {
    margin-bottom: 12px;
  }

  .analysis-item:last-child {
    margin-bottom: 0;
  }

  .url-analysis .section-title {
    padding: 0;
    font-size: 13px;
    color: #606266;
    margin-bottom: 8px;
    border-top: none;
    background-color: transparent;
    font-weight: normal;
  }

  .analysis-tag {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .analysis-tag:last-child {
    margin-right: 0;
  }
</style> 