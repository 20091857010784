<template>
  <div class="combined-dashboard">
    <el-card>
      <div slot="header" class="header">
        <span>数据关联分析</span>
        <div class="filter-container">
          <div class="filter-grid">
            <div class="filter-item-wrapper">
              <el-input
                v-model="seriesNameQuery"
                placeholder="请输入系列名"
                clearable
                class="filter-item"
              >
              </el-input>
            </div>
            <div class="filter-item-wrapper">
              <el-input
                v-model="styleNameQuery"
                placeholder="请输入广告位名称"
                clearable
                class="filter-item"
              >
              </el-input>
            </div>
            <div class="filter-item-wrapper">
              <el-input
                v-model="searchQuery"
                placeholder="请输入SID"
                clearable
                class="filter-item"
              >
              </el-input>
            </div>
            <div class="filter-item-wrapper">
              <el-select
                v-model="accountIdQuery"
                placeholder="选择账户ID"
                clearable
                multiple
                filterable
                collapse-tags
                class="filter-item"
              >
                <el-option
                  v-for="item in accountIdOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="filter-item-wrapper">
              <el-select
                v-model="mainPartQuery"
                placeholder="选择主体"
                clearable
                multiple
                filterable
                collapse-tags
                class="filter-item"
              >
                <el-option
                  v-for="item in mainPartOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="filter-item-wrapper">
              <el-select
                v-model="agentQuery"
                placeholder="选择代理"
                clearable
                multiple
                filterable
                collapse-tags
                class="filter-item"
              >
                <el-option
                  v-for="item in agentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="filter-item-wrapper">
              <el-select
                v-model="directCustomerQuery"
                placeholder="选择直客"
                clearable
                multiple
                filterable
                collapse-tags
                class="filter-item"
              >
                <el-option
                  v-for="item in directCustomerOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="filter-item-wrapper" v-if="isAdmin">
              <el-select
                v-model="belongToQuery"
                placeholder="选择归属"
                clearable
                multiple
                collapse-tags
                class="filter-item"
              >
                <el-option
                  v-for="item in belongToOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="filter-item-wrapper date-picker-wrapper">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                class="filter-item date-picker"
              ></el-date-picker>
            </div>
            <div class="filter-item-wrapper search-btn-wrapper">
              <el-button type="primary" @click="handleSearch" class="search-btn">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="summary-section">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总花费</span>
              <span class="summary-value cost">${{ (summaryData.totalSpeed || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总收入</span>
              <span class="summary-value revenue">${{ (summaryData.totalIncome || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">平均ROI</span>
              <span class="summary-value roi">{{ summaryData.avgROI || '0' }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item update-time">
              <span class="summary-label">更新时间</span>
              <span class="summary-value time">{{ summaryData.updateTime || '-' }}</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%; margin-top: 15px"
        border
        @sort-change="handleSortChange"
        :row-class-name="tableRowClassName">
        <!-- 基本信息列 -->
        <el-table-column label="基本信息">
          <el-table-column prop="seriesName" label="系列名称" width="180">
          </el-table-column>
          <el-table-column prop="sid" label="SID" width="100">
            <template slot-scope="scope">
              <el-button 
                type="text"
                @click="handleViewDetail(scope.row)"
              >
                {{ scope.row.sid }}
              </el-button>
            </template>
          </el-table-column>
           <el-table-column prop="styleName" label="广告位" width="120">
          </el-table-column>
          <el-table-column v-if="isAdmin" prop="belongTo" label="归属" width="100">
          </el-table-column>
          <el-table-column prop="date" label="日期" width="120">
            <template slot-scope="scope">
              {{ formatDate(scope.row.date) }}
            </template>
          </el-table-column>
        </el-table-column>

        <!-- TT投放数据列 -->
        <el-table-column label="FB投放数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>FB投放数据</span>
              <el-button
                type="text"
                @click="toggleTTColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllTTColumns, 'el-icon-arrow-right': showAllTTColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="ttCost" label="花费" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">${{ scope.row.ttCost.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ttCvr" label="转化率" width="90" sortable>
            <template slot-scope="scope">
              {{ scope.row.ttCvr }}%
            </template>
          </el-table-column>
          <el-table-column prop="ttCpa" label="单次成效费用" width="130" sortable>
            <template slot-scope="scope">
              <span>${{ scope.row.ttCpa.toFixed(2) }}</span>
            </template>
          </el-table-column>
          
          <!-- 可展开的列 -->
          <template v-if="showAllTTColumns">
            <!-- <el-table-column prop="ttBudget" label="预算" width="90" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttBudget.toFixed(2) }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="ttImpressions" label="广告展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="ttClicks" label="广告点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="ttConversions" label="成效次数" width="110" sortable></el-table-column>
            <el-table-column prop="ttCpm" label="广告千次展示费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpm.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCpc" label="单次链接点击费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpc.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCtr" label="素材点击率" width="120" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCtr }}%
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Google变现数据列 -->
        <el-table-column label="Google变现数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>Google变现数据</span>
              <el-button
                type="text"
                @click="toggleGoogleColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllGoogleColumns, 'el-icon-arrow-right': showAllGoogleColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="googleRevenue" label="收入" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">
                ${{ scope.row.googleRevenue.toFixed(2) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="googleImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleClicks" label="点击次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleCpc" label="每次点击费用" width="130" sortable>
            <template slot-scope="scope">
              ${{ scope.row.googleCpc.toFixed(2) }}
            </template>
          </el-table-column>

          <!-- 可展开的列 -->
          <template v-if="showAllGoogleColumns">
            <el-table-column prop="googleFunnelRequests" label="漏斗请求数" width="120" sortable></el-table-column>
            <el-table-column prop="googleFunnelImpressions" label="漏斗展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="googleFunnelClicks" label="漏斗点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="funnelShowRate" label="漏斗展示率" width="130" sortable>
               <template slot-scope="scope">
                {{ scope.row.funnelShowRate }}%
              </template>
            </el-table-column>
             <el-table-column prop="funnelClickRate" label="漏斗点击率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelClickRate }}%
              </template>
             </el-table-column>
            <el-table-column prop="googleFunnelEcpm" label="漏斗ECPM" width="120" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleFunnelEcpm.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="googleRequests" label="广告请求数" width="130" sortable></el-table-column>
            <el-table-column prop="googleCoverage" label="覆盖率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleCoverage }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleClickRate" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleEcpm" label="ECPM" width="90" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleEcpm.toFixed(2) }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- 关联指标列 -->
        <el-table-column label="关联指标">
          <el-table-column 
            prop="roi" 
            label="ROI" 
            sortable="custom"
            :sort-method="sortROI">
            <template slot-scope="scope">
              <div class="roi-cell" :class="getRoiClass(scope.row.roi)">
                {{ scope.row.roi }}%
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="数据抓取">
          <el-table-column 
            prop="" 
            label="状态" 
            width="170"
            >
            <template slot-scope="scope">
              <div>
              <el-tag   type="success" v-if="scope.row.adsenseResult === 1">变现成功</el-tag>
              <el-tag   type="danger" v-if="scope.row.adsenseResult === 0">变现异常</el-tag>
              <el-tag  type="info" v-if="scope.row.adsenseResult === ''">未变现</el-tag>
              <el-tag  style="margin-left: 6px;"   type="success" v-if="scope.row.fbResult === 1">投放成功</el-tag>
              <el-tag  style="margin-left: 6px;" type="danger" v-if="scope.row.fbResult === 0">投放异常</el-tag>
              <el-tag  style="margin-left: 6px;" type="info" v-if="scope.row.fbResult === ''">未投放</el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <!-- 账户信息列 -->
        <el-table-column label="账户信息">
          <el-table-column prop="accountId" label="账户ID" width="120"></el-table-column>
          <el-table-column prop="mainPart" label="主体" width="120"></el-table-column>
          <el-table-column prop="agent" label="代理" width="120"></el-table-column>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'CombinedDashboard',
    data() {
      return {
        loading: false,
        dateRange: [new Date(), new Date()],
        searchQuery: '',
        belongToQuery: '',
        belongToOptions: [],
        tableData: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const date = new Date()
                picker.$emit('pick', [date, date])
              }
            },
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
                picker.$emit('pick', [start, end])
              }
            }
          ],
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
        pageSize: 10,
        currentPage: 1,
        total: 0,
        sortBy: '',
        sortOrder: '',
        showAllTTColumns: false,
        showAllGoogleColumns: false,
        isAdmin: false,
        lastUpdateTime: '',
        summaryData: {
          totalSpeed: 0,
          totalIncome: 0,
          avgROI: '0',
          updateTime: '-'
        },
        seriesNameQuery: '',
        styleNameQuery: '',
        accountIdQuery: [],
        accountIdOptions: [],
        mainPartQuery: [],
        mainPartOptions: [],
        agentQuery: [],
        agentOptions: [],
        directCustomerQuery: [],
        directCustomerOptions: [],
      }
    },
    computed: {
      filteredTableData() {
        return this.tableData
      },
      totalCost() {
        console.log('计算总花费，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const cost = Number(item.ttCost) || 0
          console.log('单项花费:', cost)
          return sum + cost
        }, 0)
        console.log('总花费计算结果:', total)
        return total
      },
      totalRevenue() {
        console.log('计算总收入，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const revenue = Number(item.googleRevenue) || 0
          console.log('单项收入:', revenue)
          return sum + revenue
        }, 0)
        console.log('总收入计算结果:', total)
        return total
      },
      averageRoi() {
        console.log('计算ROI，总费:', this.totalCost, '总收入:', this.totalRevenue)
        if (!this.totalCost) return 0
        const roi = this.totalRevenue / this.totalCost
        console.log('ROI计算结果:', roi)
        return roi
      },
      totalGroups() {
        return Array.isArray(this.tableData) ? this.tableData.length : 0
      }
    },
    methods: {
      async fetchData() {
        if (this.loading) return

        if (!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) return

        this.loading = true
        const params = {
          startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dateRange[1]).format('YYYY-MM-DD'),
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          sid: this.searchQuery,
          seriesName: this.seriesNameQuery,
          styleName: this.styleNameQuery,
          sortBy: "spend",
          sortOrder: "desc"
        }

        if (this.isAdmin) {
          params.belongTo = this.belongToQuery.join(',')
        }

        // 添加新的筛选参数
        if (this.accountIdQuery && this.accountIdQuery.length > 0) {
          params.accountId = this.accountIdQuery
        }
        if (this.mainPartQuery && this.mainPartQuery.length > 0) {
          params.mainPart = this.mainPartQuery
        }
        if (this.directCustomerQuery && this.directCustomerQuery.length > 0) {
          params.directCustomer = this.directCustomerQuery
        }
        if (this.agentQuery && this.agentQuery.length > 0) {
          params.agent = this.agentQuery
        }

        try {
          const [listResponse, totalResponse] = await Promise.all([
            this.$http.post('/launch/data/dash/board/fb/list', params),
            this.$http.post('/launch/data/dash/board/fb/total', {
              startDate: params.startDate,
              endDate: params.endDate,
              sid: params.sid,
              belongTo: params.belongTo,
              seriesName: this.seriesNameQuery,
              styleName: this.styleNameQuery,
              accountId: params.accountId,
              mainPart: params.mainPart,
              directCustomer: params.directCustomer,
              agent: params.agent,
            })
          ])

          // 处理列表数据
          const responseData = listResponse.data.body?.data || []
          this.total = listResponse.data.body?.total || 0
          this.tableData = responseData.map(item => {
            const faceBookResponse = item.faceBookResponse || {}
            const adsenseResponse = item.adsenseResponse || {}

            return {
              // 基本信息
              seriesName: item.seriesName || '',
              sid: item.sid || '',
              styleName: item.styleName || '',
              date: item.statTimeDay || '',
              belongTo: item.belongTo || '',
              accountId: item.accountId || '',
              mainPart: item.mainPart || '',
              agent: item.agent || '',

              // FB投放数据
              ttBudget: Number(faceBookResponse.budget || 0),
              ttCost: Number(faceBookResponse.spend || 0),
              ttImpressions: Number(faceBookResponse.displayCount || 0),
              ttClicks: Number(faceBookResponse.clickCount || 0),
              ttConversions: Number(faceBookResponse.resultCount || 0),
              ttCpm: Number(faceBookResponse.thousandDisplaySpend || 0),
              ttCpc: Number(faceBookResponse.costPerClick || 0),
              ttCpa: Number(faceBookResponse.costPerResult || 0),
              ttCtr: Number(faceBookResponse.materialClickRate || 0),
              ttCvr: Number(faceBookResponse.conversionRate || 0),

              // Google变现数据
              googleRevenue: Number(adsenseResponse.income || 0),
              googleFunnelRequests: Number(adsenseResponse.funnelRequestCount || 0),
              googleFunnelImpressions: Number(adsenseResponse.funnelShowCount || 0),
              googleFunnelClicks: Number(adsenseResponse.funnelClickCount || 0),
              googleFunnelEcpm: Number(adsenseResponse.funnelECPM || 0),
              googleRequests: Number(adsenseResponse.requestCount || 0),
              googleImpressions: Number(adsenseResponse.showCount || 0),
              googleCoverage: Number(adsenseResponse.coverage || 0),
              googleClicks: Number(adsenseResponse.clickCount || 0),
              googleClickRate: Number(adsenseResponse.clickRate || 0),
              googleCpc: Number(adsenseResponse.perClickCost || 0),
              googleEcpm: Number(adsenseResponse.ecpm || 0),

              // 关联指标
              roi: Number(item.roi || 0),
              funnelShowRate: Number(item.funnelShowRate || 0),
              funnelClickRate: Number(item.funnelClickRate || 0),
              fbResult: item.fbResult || "",
              adsenseResult: item.adsenseResult || "",
            }
          })

          // 处理汇总数据
          if (totalResponse.data.header.code === 0 && totalResponse.data.body?.data) {
            const { updateTime, totalSpeed, totalIncome, avgROI } = totalResponse.data.body.data
            this.summaryData = {
              totalSpeed: Number(totalSpeed || 0),
              totalIncome: Number(totalIncome || 0),
              avgROI: avgROI || '0',
              updateTime: updateTime || '-'
            }
          }
        } catch (error) {
          console.error('获取数据失败:', error)
          this.$message.error('获取数据失败：' + error.message)
          // 出错时重置数据为默认值
          this.tableData = []
          this.total = 0
          this.summaryData = {
            totalSpeed: 0,
            totalIncome: 0,
            avgROI: '0',
            updateTime: '-'
          }
        } finally {
          this.loading = false
        }
      },
      async handleDateChange() {
        await this.fetchData()
      },
      formatPercentage(value) {
        if (typeof value !== 'number') return '0%'
        // 检查是否为整数
        if (Math.floor(value) === value) {
          return `${value}%`
        }
        return `${value.toFixed(2)}%`
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.currentPage = 1
        this.fetchData()
      },
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
      },
      handleSortChange({ prop, order }) {
        this.sortBy = prop
        this.sortOrder = order

        // 对数据进行排序
        if (order) {
          this.tableData.sort((a, b) => {
            let aValue = a[prop]
            let bValue = b[prop]

            // 特殊处理ROI的排序
            if (prop === 'roi') {
              aValue = parseFloat(aValue) || 0
              bValue = parseFloat(bValue) || 0
            }

            if (order === 'ascending') {
              return aValue > bValue ? 1 : -1
            } else {
              return aValue < bValue ? 1 : -1
            }
          })
        }
      },
      toggleTTColumns() {
        this.showAllTTColumns = !this.showAllTTColumns
      },
      toggleGoogleColumns() {
        this.showAllGoogleColumns = !this.showAllGoogleColumns
      },
      async handleSearch() {
        this.currentPage = 1
        await this.fetchData()
      },
      getRoiClass(roi) {
        const value = parseFloat(roi);
        if (value < 40) return 'roi-blue';
        if (value < 90) {
          if (value < 65) return 'roi-orange';  // 40-65 显示橙色
          return 'roi-yellow';  // 65-90 显示黄色
        }
        if (value < 150) return 'roi-light-green'; // 90-150 显示浅绿色
        return 'roi-green'; // 150以上显示深绿色
      },
      // 修改获取归属选项的方法
      async fetchBelongToOptions() {
        try {
          // 调用用户列表接口获取所有用户
          const { total } = await this.$store.dispatch('userManagement/fetchUsers', {
            pageNum: 1,
            pageSize: 999 // 获取足够大的数量以包含所有用户
          })

          // 从store中获取用户列表
          const users = this.$store.state.userManagement.users

          // 提取用户名作为归属选项
          this.belongToOptions = users.map(user => ({
            value: user.accountName,
            label: user.accountName
          }))
        } catch (error) {
          console.error('获取归属选项失败:', error)
          this.$message.error('获取归属选项失败：' + error.message)
        }
      },
      // 添加获取账户ID、主体、代理选项的方法
      async fetchFilterOptions() {
        try {
          // 使用账户关系表接口获取数据，平台类型为fb，pageSize设为9999
          const response = await this.$http.post('/launch/merchant/mapping/list', {
            pageNum: 1,
            pageSize: 9999,
            platformType: 'fb'
          })

          if (response.data && response.data.header && response.data.header.code === 0) {
            const data = response.data.body.data || []

            // 提取不重复的账户ID、主体、直客和代理商信息
            const accountIds = [...new Set(data.map(item => item.accountId))].filter(Boolean)
            const mainParts = [...new Set(data.map(item => item.mainPart))].filter(Boolean)
            const directCustomers = [...new Set(data.map(item => item.directCustomer))].filter(Boolean)
            const agents = [...new Set(data.map(item => item.agent))].filter(Boolean)

            // 处理账户ID选项
            this.accountIdOptions = accountIds.map(id => ({
              value: id,
              label: id
            }))

            // 处理主体选项
            this.mainPartOptions = mainParts.map(name => ({
              value: name,
              label: name
            }))

            // 处理直客选项
            this.directCustomerOptions = directCustomers.map(name => ({
              value: name,
              label: name
            }))

            // 处理代理选项
            this.agentOptions = agents.map(name => ({
              value: name,
              label: name
            }))
          }
        } catch (error) {
          console.error('获取筛选选项失败:', error)
          this.$message.error('获取筛选选项失败：' + error.message)
        }
      },
      // 添加ROI排序方法
      sortROI(a, b) {
        // 移除百分号并转换为数字进行比较
        const roiA = parseFloat(a.roi) || 0;
        const roiB = parseFloat(b.roi) || 0;
        return roiA - roiB;
      },
      handleViewDetail(row) {
        const path = this.$router.resolve({
          name: 'DataDetail',
          params: {
            sid: row.sid,
            seriesName: row.seriesName
          },
          query: {
            platform: 'fb'
          }
        })
        window.open(path.href, '_blank')
      },
      async initialize() {
        try {
          // 直接从 store 获取管理员状态
          this.isAdmin = this.$store.state.user.isAdmin

          // 如果是管理员,获取归属选项
          if (this.isAdmin) {
            await this.fetchBelongToOptions()
          }

          // 获取筛选选项
          await this.fetchFilterOptions()

          // 设置默认日期并请求数据
          const today = new Date()
          this.$set(this, 'dateRange', [today, today])

          // 请求数据
          await this.fetchData()
        } catch (error) {
          console.error('初始化失败:', error)
          this.$message.error('初始化失败：' + error.message)
        }
      },
      // 添加行类名方法
      tableRowClassName({ row }) {
        if (row.adsenseResult === 0 || row.fbResult === 0) {
          return 'error-row'
        }
        return ''
      },
    },
    watch: {
      belongToQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      accountIdQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      mainPartQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      directCustomerQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      agentQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      dateRange: {
        handler(newVal) {
          if (newVal && newVal[0] && newVal[1]) {
            this.currentPage = 1
            this.fetchData()
          }
        },
        immediate: false
      }
    },
    created() {
      // 直接调用初始化方法
      this.initialize()
    }
  }
</script>

<style scoped>
  .combined-dashboard {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;
  }

  .bold {
    font-weight: bolder;
  }

  /* 主卡片样式 */
  .combined-dashboard> :deep(.el-card) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.el-card__body) {
    flex: 1;
    padding: 15px;
    min-height: 0;
  }

  /* 头部样式 */
  .header {
    display: flex;
    flex-direction: column;
  }

  .header>span {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 20px;
  }

  .filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 16px;
    width: 100%;
  }

  .filter-item-wrapper {
    position: relative;
  }

  .filter-item {
    width: 100%;
  }

  .date-picker-wrapper {
    grid-column: span 2;
  }

  .search-btn-wrapper {}

  .date-picker {
    width: 100%;
  }

  .search-btn {
    width: 100px;
    height: 36px;
  }

  /* 表格相关样式 */
  .series-name {
    display: inline-block;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :deep(.el-table) {
    height: auto;
  }

  :deep(.el-table th .cell) {
    display: flex;
    align-items: center;
  }

  /* 分页样式 */
  .pagination-container {
    margin: 20px 0;
    text-align: right;
  }

  /* 汇总部分样式优化 */
  .summary-section {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .summary-item {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #f0f0f0;
  }

  .summary-item.update-time {
    border-right: none;
    justify-content: space-between;
  }

  .summary-item.update-time .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-item.update-time .summary-value.time {
    font-size: 12px;
    color: #8c8c8c;
  }

  .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-value {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }

  .summary-value.cost {
    color: #ff4d4f;
  }

  .summary-value.revenue {
    color: #52c41a;
  }

  .summary-value.roi {
    color: #1890ff;
  }

  .summary-value.count {
    color: #722ed1;
  }

  /* 表格样式优化 */
  :deep(.el-table) {
    margin-top: 15px;
  }

  :deep(.el-table th.el-table__cell) {
    background-color: #fafafa;
    font-weight: 600;
    padding: 4px 0;
    height: 36px;
  }

  :deep(.el-table--border th.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table--border td.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table__header .cell) {
    line-height: 28px;
    height: 28px;
  }

  :deep(.el-table__header .cell .el-button) {
    padding: 0 3px;
    height: 28px;
    line-height: 28px;
  }

  /* 分页样式优化 */
  .pagination-container {
    margin: 16px 0 0;
    padding-top: 16px;
    border-top: 1px solid #f0f0f0;
  }

  /* 其他工具样式 */
  .el-date-editor.el-input {
    width: 220px;
  }

  .el-button [class^="el-icon-"] {
    margin-left: 0;
  }

  /* 滚动条样式 */
  :deep(.el-card__body::-webkit-scrollbar) {
    width: 6px;
  }

  :deep(.el-card__body::-webkit-scrollbar-thumb) {
    background: #dcdfe6;
    border-radius: 3px;
  }

  :deep(.el-card__body::-webkit-scrollbar-track) {
    background: #f5f7fa;
  }

  :deep(.highlight-column) {
    background-color: #f0f5ff !important;
  }

  :deep(.el-table__row:hover .highlight-column) {
    background-color: #f0f5ff !important;
  }

  .roi-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 100%;
  }

  .roi-blue {
    background-color: #f0f5ff;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-orange {
    background-color: #ffccc7;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-yellow {
    background-color: #ffd666;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-light-green {
    background-color: #b7eb8f;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-green {
    background-color: #52c41a;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  /* 为所有表格单元格添加内边距 */
  :deep(.el-table td.el-table__cell) {
    padding: 0;
    height: 40px;
  }

  :deep(.el-table .cell) {
    padding: 0 5px;
    /* 添加左右5px内边距 */
    height: 100%;
    display: flex;
    /* 使用flex布局实现水平垂直居中 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
  }

  /* ROI列保持无内边距 */
  :deep(.el-table td.el-table__cell:has(.roi-cell) .cell) {
    padding: 0;
  }

  /* 对于需要左对齐的列（如系列名称等），可以单独设置 */
  :deep(.el-table td.el-table__cell .cell .series-name) {
    justify-content: flex-start;
  }

  /* 确保悬停状态保持背景色 */
  :deep(.el-table__row:hover .roi-blue) {
    background-color: #f0f5ff;
  }

  :deep(.el-table__row:hover .roi-orange) {
    background-color: #ffccc7;
  }

  :deep(.el-table__row:hover .roi-yellow) {
    background-color: #ffd666;
  }

  :deep(.el-table__row:hover .roi-light-green) {
    background-color: #b7eb8f;
  }

  :deep(.el-table__row:hover .roi-green) {
    background-color: #52c41a;
  }

  /* 添加下拉框样式 */
  :deep(.el-select) {
    width: 200px;
  }

  :deep(.el-select .el-select__tags) {
    max-width: calc(100% - 30px);
  }

  :deep(.el-input) {
    width: 220px;
  }

  /* 修改错误行样式 */
  :deep(.error-row) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover > td) {
    background-color: #fde2e2 !important;
  }

  /* 确保高亮列在错误行中也显示错误背景色 */
  :deep(.error-row .highlight-column) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover .highlight-column) {
    background-color: #fde2e2 !important;
  }

  /* 确保ROI列在错误行中也显示错误背景色 */
  :deep(.error-row .roi-cell) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover .roi-cell) {
    background-color: #fde2e2 !important;
  }

  /* 覆盖所有ROI状态类在错误行中的样式 */
  :deep(.error-row .roi-blue),
  :deep(.error-row .roi-orange),
  :deep(.error-row .roi-yellow),
  :deep(.error-row .roi-light-green),
  :deep(.error-row .roi-green) {
    background-color: #fef0f0 !important;
    color: #333 !important;
  }

  :deep(.error-row:hover .roi-blue),
  :deep(.error-row:hover .roi-orange),
  :deep(.error-row:hover .roi-yellow),
  :deep(.error-row:hover .roi-light-green),
  :deep(.error-row:hover .roi-green) {
    background-color: #fde2e2 !important;
    color: #333 !important;
  }
</style> 