<template>
  <div class="account-relationship-container">
    <h2 class="page-title">账户关系表</h2>
    
    <!-- 搜索和筛选区域 -->
    <div class="filter-container">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item label="平台类型">
          <el-select v-model="queryParams.platformType" placeholder="请选择平台类型" @change="handleSearch">
            <el-option label="TikTok" value="tiktok"></el-option>
            <el-option label="Facebook" value="fb"></el-option>
            <el-option label="Kwai" value="kwai"></el-option>
            <el-option label="网站投放-TT" value="webSiteTT"></el-option>
            <el-option label="网站投放-谷歌" value="webSiteGoogle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号ID">
          <el-input v-model="queryParams.accountIds" placeholder="多个ID用逗号分隔"></el-input>
        </el-form-item>
        <el-form-item label="主体信息">
          <el-input v-model="queryParams.mainParts" placeholder="多个主体用逗号分隔"></el-input>
        </el-form-item>
        <el-form-item label="直客信息">
          <el-input v-model="queryParams.directCustomers" placeholder="多个直客用逗号分隔"></el-input>
        </el-form-item>
        <el-form-item label="代理商信息">
          <el-input v-model="queryParams.agents" placeholder="多个代理商用逗号分隔"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="success" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <!-- 数据表格 -->
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="platformType"
        label="平台类型"
        width="120">
        <template slot-scope="scope">
          <span>{{ formatPlatformType(scope.row.platformType) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="accountId"
        label="账号ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="mainPart"
        label="主体信息">
      </el-table-column>
      <el-table-column
        prop="directCustomer"
        label="直客信息">
      </el-table-column>
      <el-table-column
        prop="agent"
        label="代理商信息">
      </el-table-column>
      <el-table-column
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    
    <!-- 新增/编辑对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" @closed="handleDialogClosed">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="平台类型" prop="platformType">
          <el-select v-model="form.platformType" placeholder="请选择平台类型" style="width: 100%">
            <el-option label="TikTok" value="tiktok"></el-option>
            <el-option label="Facebook" value="fb"></el-option>
            <el-option label="Kwai" value="kwai"></el-option>
            <el-option label="网站投放-TT" value="webSiteTT"></el-option>
            <el-option label="网站投放-谷歌" value="webSiteGoogle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号ID" prop="accountId">
          <el-input v-model="form.accountId" placeholder="请输入账号ID"></el-input>
        </el-form-item>
        <el-form-item label="主体信息" prop="mainPart">
          <el-input v-model="form.mainPart" placeholder="请输入主体信息"></el-input>
        </el-form-item>
        <el-form-item label="直客信息" prop="directCustomer">
          <el-input v-model="form.directCustomer" placeholder="请输入直客信息（可选）"></el-input>
        </el-form-item>
        <el-form-item label="代理商信息" prop="agent">
          <el-input v-model="form.agent" placeholder="请输入代理商信息"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AccountRelationship',
    data() {
      return {
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          platformType: 'tiktok', // 默认选择TikTok平台
          accountIds: '',
          mainParts: '',
          directCustomers: '',
          agents: ''
        },
        // 表格数据
        tableData: [],
        // 总记录数
        total: 0,
        // 加载状态
        loading: false,
        // 对话框可见性
        dialogVisible: false,
        // 对话框标题
        dialogTitle: '新增账户关系',
        // 表单数据
        form: {
          platformType: '',
          accountId: '',
          mainPart: '',
          directCustomer: '',
          agent: ''
        },
        // 表单验证规则
        rules: {
          platformType: [
            { required: true, message: '请选择平台类型', trigger: 'change' }
          ],
        },
        // 编辑模式标志
        isEdit: false
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 格式化平台类型显示
      formatPlatformType(type) {
        const platformMap = {
          'tiktok': 'TikTok',
          'fb': 'Facebook',
          'kwai': 'Kwai',
          'webSiteTT': '网站投放-TT',
          'webSiteGoogle': '网站投放-谷歌'
        }
        return platformMap[type] || type
      },

      // 获取数据列表
      fetchData() {
        this.loading = true
        // 构建API请求URL
        const url = `/launch/merchant/mapping/list`

        // 处理逗号分隔的输入，转换为数组
        const params = {
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize,
          platformType: this.queryParams.platformType
        }

        // 处理账号ID
        if (this.queryParams.accountIds && this.queryParams.accountIds.trim()) {
          params.accountId = this.queryParams.accountIds.split(',').map(item => item.trim()).filter(item => item)
        }

        // 处理主体信息
        if (this.queryParams.mainParts && this.queryParams.mainParts.trim()) {
          params.mainPart = this.queryParams.mainParts.split(',').map(item => item.trim()).filter(item => item)
        }

        // 处理直客信息
        if (this.queryParams.directCustomers && this.queryParams.directCustomers.trim()) {
          params.directCustomer = this.queryParams.directCustomers.split(',').map(item => item.trim()).filter(item => item)
        }

        // 处理代理商信息
        if (this.queryParams.agents && this.queryParams.agents.trim()) {
          params.agent = this.queryParams.agents.split(',').map(item => item.trim()).filter(item => item)
        }

        this.$http.post(url, params)
          .then(response => {
            if (response.data && response.data.header && response.data.header.code === 0) {
              this.tableData = response.data.body.data || []
              this.total = response.data.body.total || 0
            } else {
              this.$message.error(response.data.header.msg || '获取数据失败')
            }
          })
          .catch(error => {
            console.error('获取数据失败:', error)
            this.$message.error('获取数据失败，请稍后重试')
          })
          .finally(() => {
            this.loading = false
          })
      },

      // 处理搜索
      handleSearch() {
        this.queryParams.pageNum = 1
        this.fetchData()
      },

      // 处理新增
      handleAdd() {
        this.isEdit = false
        this.dialogTitle = '新增账户关系'
        // 先关闭对话框，确保表单重置
        this.dialogVisible = false

        // 使用nextTick确保DOM更新后再重新打开对话框
        this.$nextTick(() => {
          // 完全重置表单数据，创建一个全新的对象
          this.form = Object.assign({}, {
            platformType: this.queryParams.platformType,
            accountId: '',
            mainPart: '',
            directCustomer: '',
            agent: ''
          })

          // 重新打开对话框
          this.dialogVisible = true

          // 重置表单验证
          this.$nextTick(() => {
            if (this.$refs.form) {
              this.$refs.form.clearValidate()
            }
          })
        })
      },

      // 处理编辑
      handleEdit(row) {
        this.isEdit = true
        this.dialogTitle = '编辑账户关系'
        // 先关闭对话框，确保表单重置
        this.dialogVisible = false

        // 使用nextTick确保DOM更新后再重新打开对话框
        this.$nextTick(() => {
          // 设置表单数据为当前行数据，使用深拷贝避免引用问题
          this.form = JSON.parse(JSON.stringify(row))

          // 重新打开对话框
          this.dialogVisible = true

          // 重置表单验证
          this.$nextTick(() => {
            if (this.$refs.form) {
              this.$refs.form.clearValidate()
            }
          })
        })
      },

      // 提交表单
      submitForm() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true

            // 根据是否为编辑模式选择不同的API
            const url = this.isEdit
              ? '/launch/merchant/mapping/update'
              : '/launch/merchant/mapping/save'

            this.$http.post(url, this.form)
              .then(response => {
                if (response.data && response.data.header && response.data.header.code === 0) {
                  this.$message.success(this.isEdit ? '更新成功' : '添加成功')
                  this.dialogVisible = false
                  this.fetchData() // 刷新数据
                } else {
                  this.$message.error(response.data.header.msg || (this.isEdit ? '更新失败' : '添加失败'))
                }
              })
              .catch(error => {
                console.error(this.isEdit ? '更新失败:' : '添加失败:', error)
                this.$message.error(this.isEdit ? '更新失败，请稍后重试' : '添加失败，请稍后重试')
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
      },

      // 处理每页显示数量变化
      handleSizeChange(val) {
        this.queryParams.pageSize = val
        this.fetchData()
      },

      // 处理页码变化
      handleCurrentChange(val) {
        this.queryParams.pageNum = val
        this.fetchData()
      },

      // 处理对话框关闭事件
      handleDialogClosed() {
        // 重置表单数据
        this.form = {
          platformType: '',
          accountId: '',
          mainPart: '',
          directCustomer: '',
          agent: ''
        }
        // 重置表单验证
        if (this.$refs.form) {
          this.$refs.form.clearValidate()
        }
      }
    }
  }
</script>

<style scoped>
  .account-relationship-container {
    padding: 20px;
  }

  .page-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: #303133;
  }

  .filter-container {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f5f7fa;
    border-radius: 4px;
  }

  .pagination-container {
    margin-top: 20px;
    text-align: right;
  }
</style> 