<template>
  <div class="combined-dashboard">
    <el-card>
      <div slot="header" class="header">
        <span>数据详情</span>
        <div class="header-controls">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            style="margin-right: 15px"
            @change="handleDateChange"
          ></el-date-picker>
        </div>
      </div>
      
      <div class="summary-section" v-if="!isKwai">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总花费</span>
              <span class="summary-value cost">${{ (summaryData.totalSpeed || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总收入</span>
              <span class="summary-value revenue">${{ (summaryData.totalIncome || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">平均ROI</span>
              <span class="summary-value roi">{{ summaryData.avgROI || '0' }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item update-time">
              <span class="summary-label">更新时间</span>
              <span class="summary-value time">{{ summaryData.updateTime || '-' }}</span>
            </div>
          </el-col>
        </el-row>
      </div>



      <el-table
        v-if="isKwai"
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%; margin-top: 15px"
        border
        @sort-change="handleSortChange"
        @expand-change="handleExpandChange"
        :row-key="getRowKey"
        :expand-row-keys="Array.from(expandedRowKeys)"
        :row-class-name="tableRowClassName">
        <!-- 展开列移到最前面 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="expand-detail">
              <el-table
                v-loading="props.row.loading"
                :data="props.row.hourlyData || []"
                style="width: 100%; margin: 0;"
                size="mini"
                border
                header-align="center"
                align="center">
                <el-table-column
                  prop="statTimeClock"
                  label="时间"
                  align="center">
                  <template slot-scope="scope">
                    {{ scope.row.statTimeClock }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttSpend"
                  label="花费"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttAvgTransformSpend"
                  label="转化费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttAvgTransformSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="transformRate"
                  label="转化率"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.transformRate || 0 }}%</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseIncome" 
                  label="收入"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseIncome.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsensePerClickCost"
                  label="点击费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsensePerClickCost.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseECPM"
                  label="ECPM"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseECPM.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="roi"
                  label="ROI"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.roi || 0 }}%</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!-- 基本信息列 -->
        <el-table-column label="基本信息">
          <el-table-column prop="seriesName" label="系列名称" width="180">
          </el-table-column>
          <el-table-column prop="sid" label="SID" width="100">
            <template slot-scope="scope">
              <el-button 
                type="text"
                @click="handleViewDetail(scope.row)"
              >
                {{ scope.row.sid }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="styleName" label="广告位" width="120">
          </el-table-column>
          <el-table-column v-if="isAdmin" prop="belongTo" label="归属" width="100">
          </el-table-column>
          <el-table-column prop="date" label="日期" width="120">
            <template slot-scope="scope">
              {{ scope.row.date || '-' }}
            </template>
          </el-table-column>
        </el-table-column>

         <!-- 添加Kwai投放数据列 -->
        <el-table-column label="KWai投放数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>KWai投放数据</span>
              <el-button
                type="text"
                @click="toggleTTColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllTTColumns, 'el-icon-arrow-right': showAllTTColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="kwaiCost" label="花费" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">${{ scope.row.kwaiCost.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="kwaiCvr" label="转化率" width="90" sortable>
            <template slot-scope="scope">
              {{ scope.row.kwaiCvr }}%
            </template>
          </el-table-column>
          <el-table-column prop="kwaiCtr" label="点击率" width="90" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.kwaiCtr.toFixed(2) }}%</span>
            </template>
          </el-table-column>
          
          <!-- 可展开的列 -->
          <template v-if="showAllTTColumns">
            <el-table-column prop="kwaiImpressions" label="广告展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="kwaiClicks" label="广告点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="kwaiConversions" label="广告转化次数" width="130" sortable></el-table-column>
            <el-table-column prop="kwaiCpa" label="广告每次转化费用" width="160" sortable></el-table-column>
            <el-table-column prop="kwaiCpm" label="广告千次展示费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.kwaiCpm.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="kwaiCpc" label="广告每次点击费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.kwaiCpc.toFixed(2) }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Google变现数据列 -->
        <el-table-column label="Google变现数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>Google变现数据</span>
              <el-button
                type="text"
                @click="toggleGoogleColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllGoogleColumns, 'el-icon-arrow-right': showAllGoogleColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="googleRevenue" label="收入" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">
                ${{ scope.row.googleRevenue.toFixed(2) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="googleImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleClicks" label="点击次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleCpc" label="每次点击费用" width="130" sortable>
            <template slot-scope="scope">
              ${{ scope.row.googleCpc.toFixed(2) }}
            </template>
          </el-table-column>

          <!-- 可展开的列 -->
          <template v-if="showAllGoogleColumns">
            <el-table-column prop="googleFunnelRequests" label="漏斗请求数" width="120" sortable></el-table-column>
            <el-table-column prop="googleFunnelImpressions" label="漏斗展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="googleFunnelClicks" label="漏斗点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="funnelShowRate" label="漏斗展示率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelShowRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="funnelClickRate" label="漏斗点击率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleFunnelEcpm" label="漏斗ECPM" width="120" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleFunnelEcpm.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="googleRequests" label="广告请求数" width="130" sortable></el-table-column>
            <el-table-column prop="googleCoverage" label="覆盖率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleCoverage }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleClickRate" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleEcpm" label="ECPM" width="90" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleEcpm.toFixed(2) }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- 关联指标列 -->
        <el-table-column label="关联指标">
          <el-table-column 
            prop="roi" 
            label="ROI" 
            sortable="custom"
            :sort-method="sortROI">
            <template slot-scope="scope">
              <div class="roi-cell" :class="getRoiClass(scope.row.roi)">
                {{ scope.row.roi }}%
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="数据抓取">
          <el-table-column 
            prop="" 
            label="状态" 
            width="170"
            >
            <template slot-scope="scope">
              <div>
              <el-tag   type="success" v-if="scope.row.adsenseResult === 1">变现成功</el-tag>
              <el-tag   type="danger" v-if="scope.row.adsenseResult === 0">变现异常</el-tag>
              <el-tag  type="info" v-if="scope.row.adsenseResult === ''">未变现</el-tag>
              <el-tag  style="margin-left: 6px;"   type="success" v-if="scope.row.fbResult === 1">投放成功</el-tag>
              <el-tag  style="margin-left: 6px;" type="danger" v-if="scope.row.fbResult === 0">投放异常</el-tag>
              <el-tag  style="margin-left: 6px;" type="info" v-if="scope.row.fbResult === ''">未投放</el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        
        <!-- 账户信息列 -->
        <el-table-column label="账户信息">
          <el-table-column prop="accountId" label="账户ID" width="120"></el-table-column>
          <el-table-column prop="mainPart" label="主体" width="120"></el-table-column>
          <el-table-column prop="agent" label="代理" width="120"></el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        v-loading="loading"
        v-else-if="isFb"
        :data="filteredTableData"
        style="width: 100%; margin-top: 15px"
        border
       @sort-change="handleSortChange"
        @expand-change="handleExpandChange"
        :row-key="getRowKey"
        :expand-row-keys="Array.from(expandedRowKeys)"
        :row-class-name="tableRowClassName">
        <!-- 展开列移到最前面 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="expand-detail">
              <el-table
                v-loading="props.row.loading"
                :data="props.row.hourlyData || []"
                style="width: 100%; margin: 0;"
                size="mini"
                border
                header-align="center"
                align="center">
                <el-table-column
                  prop="statTimeClock"
                  label="时间"
                  align="center">
                  <template slot-scope="scope">
                    {{ scope.row.statTimeClock }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttSpend"
                  label="花费"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttAvgTransformSpend"
                  label="单次成效费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttAvgTransformSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="transformRate"
                  label="转化率"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.transformRate || 0 }}%</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseIncome" 
                  label="收入"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseIncome.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsensePerClickCost"
                  label="点击费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsensePerClickCost.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseECPM"
                  label="ECPM"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseECPM.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="roi"
                  label="ROI"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.roi || 0 }}%</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!-- 基本信息列 -->
        <el-table-column label="基本信息">
          <el-table-column prop="seriesName" label="系列名称" width="180">
          </el-table-column>
          <el-table-column prop="sid" label="SID" width="100">
            <template slot-scope="scope">
              <el-button 
                type="text"
                @click="handleViewDetail(scope.row)"
              >
                {{ scope.row.sid }}
              </el-button>
            </template>
          </el-table-column>
           <el-table-column prop="styleName" label="广告位" width="120">
          </el-table-column>
          <el-table-column v-if="isAdmin" prop="belongTo" label="归属" width="100">
          </el-table-column>
          <el-table-column prop="date" label="日期" width="120">
            <template slot-scope="scope">
              {{ formatDate(scope.row.date) }}
            </template>
          </el-table-column>
        </el-table-column>

        <!-- TT投放数据列 -->
        <el-table-column label="FB投放数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>FB投放数据</span>
              <el-button
                type="text"
                @click="toggleTTColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllTTColumns, 'el-icon-arrow-right': showAllTTColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="ttCost" label="花费" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">${{ scope.row.ttCost.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ttCvr" label="转化率" width="90" sortable>
            <template slot-scope="scope">
              {{ scope.row.ttCvr }}%
            </template>
          </el-table-column>
          <el-table-column prop="ttCpa" label="单次成效费用" width="130" sortable>
            <template slot-scope="scope">
              <span>${{ scope.row.ttCpa.toFixed(2) }}</span>
            </template>
          </el-table-column>
          
          <!-- 可展开的列 -->
          <template v-if="showAllTTColumns">
            <!-- <el-table-column prop="ttBudget" label="预算" width="90" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttBudget.toFixed(2) }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="ttImpressions" label="广告展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="ttClicks" label="广告点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="ttConversions" label="成效次数" width="110" sortable></el-table-column>
            <el-table-column prop="ttCpm" label="广告千次展示费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpm.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCpc" label="单次链接点击费用" width="160" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpc.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCtr" label="素材点击率" width="120" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCtr }}%
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Google变现数据列 -->
        <el-table-column label="Google变现数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>Google变现数据</span>
              <el-button
                type="text"
                @click="toggleGoogleColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllGoogleColumns, 'el-icon-arrow-right': showAllGoogleColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="googleRevenue" label="收入" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">
                ${{ scope.row.googleRevenue.toFixed(2) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="googleImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleClicks" label="点击次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleCpc" label="每次点击费用" width="130" sortable>
            <template slot-scope="scope">
              ${{ scope.row.googleCpc.toFixed(2) }}
            </template>
          </el-table-column>

          <!-- 可展开的列 -->
          <template v-if="showAllGoogleColumns">
            <el-table-column prop="googleFunnelRequests" label="漏斗请求数" width="120" sortable></el-table-column>
            <el-table-column prop="googleFunnelImpressions" label="漏斗展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="googleFunnelClicks" label="漏斗点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="funnelShowRate" label="漏斗展示率" width="130" sortable>
               <template slot-scope="scope">
                {{ scope.row.funnelShowRate }}%
              </template>
            </el-table-column>
             <el-table-column prop="funnelClickRate" label="漏斗点击率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelClickRate }}%
              </template>
             </el-table-column>
            <el-table-column prop="googleFunnelEcpm" label="漏斗ECPM" width="120" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleFunnelEcpm.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="googleRequests" label="广告请求数" width="130" sortable></el-table-column>
            <el-table-column prop="googleCoverage" label="覆盖率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleCoverage }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleClickRate" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleEcpm" label="ECPM" width="90" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleEcpm.toFixed(2) }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- 关联指标列 -->
        <el-table-column label="关联指标">
          <el-table-column 
            prop="roi" 
            label="ROI" 
            sortable="custom"
            :sort-method="sortROI">
            <template slot-scope="scope">
              <div class="roi-cell" :class="getRoiClass(scope.row.roi)">
                {{ scope.row.roi }}%
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="数据抓取">
          <el-table-column 
            prop="" 
            label="状态" 
            width="170"
            >
            <template slot-scope="scope">
              <div>
              <el-tag   type="success" v-if="scope.row.adsenseResult === 1">变现成功</el-tag>
              <el-tag   type="danger" v-if="scope.row.adsenseResult === 0">变现异常</el-tag>
              <el-tag  type="info" v-if="scope.row.adsenseResult === ''">未变现</el-tag>
              <el-tag  style="margin-left: 6px;"   type="success" v-if="scope.row.fbResult === 1">投放成功</el-tag>
              <el-tag  style="margin-left: 6px;" type="danger" v-if="scope.row.fbResult === 0">投放异常</el-tag>
              <el-tag  style="margin-left: 6px;" type="info" v-if="scope.row.fbResult === ''">未投放</el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="账户信息">
          <el-table-column prop="accountId" label="账户ID" width="120"></el-table-column>
          <el-table-column prop="mainPart" label="主体" width="120"></el-table-column>
          <el-table-column prop="agent" label="代理" width="120"></el-table-column>
        </el-table-column>
      </el-table>

            <el-table
            v-else
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%; margin-top: 15px"
        border
        @sort-change="handleSortChange"
        @expand-change="handleExpandChange"
        :row-key="getRowKey"
        :expand-row-keys="Array.from(expandedRowKeys)"
        :row-class-name="tableRowClassName">
        <!-- 展开列移到最前面 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="expand-detail">
              <el-table
                v-loading="props.row.loading"
                :data="props.row.hourlyData || []"
                style="width: 80%; margin: 0;"
                size="mini"
                border
                header-align="center"
                align="center">
                <el-table-column
                  prop="statTimeClock"
                  label="时间"
                  align="center">
                  <template slot-scope="scope">
                    {{ scope.row.statTimeClock }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttSpend"
                  label="花费"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ttAvgTransformSpend"
                  label="转化费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.ttAvgTransformSpend.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="transformRate"
                  label="转化率"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.transformRate || 0 }}%</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseIncome" 
                  label="收入"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseIncome.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsensePerClickCost"
                  label="点击费用"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsensePerClickCost.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adsenseECPM"
                  label="ECPM"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">${{ scope.row.adsenseECPM.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="roi"
                  label="ROI"
                  align="center">
                  <template slot-scope="scope">
                    <span class="number-cell">{{ scope.row.roi || 0 }}%</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <!-- 基本信息列 -->
        <el-table-column label="基本信息">
          <el-table-column prop="seriesName" label="系列名称" width="180">
          </el-table-column>
          <el-table-column prop="sid" label="SID" width="100">
          </el-table-column>
           <el-table-column prop="styleName" label="广告位" width="120">
          </el-table-column>
          <el-table-column v-if="isAdmin" prop="belongTo" label="归属" width="100">
          </el-table-column>
          <el-table-column prop="date" label="日期" width="120" sortable="custom">
            <template slot-scope="scope">
              {{ formatDate(scope.row.date) }}
            </template>
          </el-table-column>
        </el-table-column>

        <!-- TT投放数据列 -->
        <el-table-column label="TT投放数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>TT投放数据</span>
              <el-button
                type="text"
                @click="toggleTTColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllTTColumns, 'el-icon-arrow-right': showAllTTColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="ttCost" label="花费" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">${{ scope.row.ttCost.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ttImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="ttConversions" label="转化次数" width="110" sortable></el-table-column>
          <el-table-column prop="ttCpa" label="转化费用" width="110" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpa.toFixed(2) }}</span>
              </template>
            </el-table-column>
          
          <!-- 可展开的列 -->
          <template v-if="showAllTTColumns">
            <el-table-column prop="ttClicks" label="点击次数" width="110" sortable></el-table-column>
            <el-table-column prop="ttCpm" label="千次展示费用" width="130" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpm.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCpc" label="点击费用" width="110" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpc.toFixed(2) }}</span>
              </template>
            </el-table-column>
            
            <el-table-column prop="ttCtr" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCtr }}%
              </template>
            </el-table-column>
            <el-table-column prop="ttCvr" label="转化率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCvr }}%
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Google变现数据列 -->
        <el-table-column label="Google变现数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>Google变现数据</span>
              <el-button
                type="text"
                @click="toggleGoogleColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllGoogleColumns, 'el-icon-arrow-right': showAllGoogleColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="googleRevenue" label="收入" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">
                ${{ scope.row.googleRevenue.toFixed(2) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="googleImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleClicks" label="点击次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleCpc" label="每次点击费用" width="130" sortable>
            <template slot-scope="scope">
              ${{ scope.row.googleCpc.toFixed(2) }}
            </template>
          </el-table-column>

          <!-- 可展开的列 -->
          <template v-if="showAllGoogleColumns">
            <el-table-column prop="googleFunnelRequests" label="漏斗请求数" width="120" sortable></el-table-column>
            <el-table-column prop="googleFunnelImpressions" label="漏斗展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="googleFunnelClicks" label="漏斗点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="funnelShowRate" label="漏斗展示率" width="130" sortable>
               <template slot-scope="scope">
                {{ scope.row.funnelShowRate }}%
              </template>
            </el-table-column>
             <el-table-column prop="funnelClickRate" label="漏斗点击率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelClickRate }}%
              </template>
             </el-table-column>
            <el-table-column prop="googleFunnelEcpm" label="漏斗ECPM" width="120" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleFunnelEcpm.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="googleRequests" label="广告请求数" width="130" sortable></el-table-column>
            <el-table-column prop="googleCoverage" label="覆盖率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleCoverage }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleClickRate" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleEcpm" label="ECPM" width="90" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleEcpm.toFixed(2) }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- 关联指标列 -->
        <el-table-column label="关联指标">
          <el-table-column 
            prop="roi" 
            label="ROI" 
            sortable="custom"
            :sort-method="sortROI">
            <template slot-scope="scope">
              <div class="roi-cell" :class="getRoiClass(scope.row.roi)">
                {{ scope.row.roi }}%
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="数据抓取">
          <el-table-column 
            prop="" 
            label="状态" 
            width="170"
            >
            <template slot-scope="scope">
              <div>
              <el-tag   type="success" v-if="scope.row.adsenseResult === 1">变现成功</el-tag>
              <el-tag   type="danger" v-if="scope.row.adsenseResult === 0">变现异常</el-tag>
              <el-tag  type="info" v-if="scope.row.adsenseResult === ''">未变现</el-tag>
              <el-tag  style="margin-left: 6px;"   type="success" v-if="scope.row.ttResult === 1">投放成功</el-tag>
              <el-tag  style="margin-left: 6px;" type="danger" v-if="scope.row.ttResult === 0">投放异常</el-tag>
              <el-tag  style="margin-left: 6px;" type="info" v-if="scope.row.ttResult === ''">未投放</el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <!-- 账户信息列 -->
        <el-table-column label="账户信息">
          <el-table-column prop="accountId" label="账户ID" width="100">
          </el-table-column>
          <el-table-column prop="mainPart" label="主体" width="120">
          </el-table-column>
          <el-table-column prop="agent" label="代理" width="120">
          </el-table-column>
          <el-table-column prop="directCustomer" label="直客" width="120">
          </el-table-column>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

      <div class="operation-log-section">
        <div class="operation-log-header">
          <span class="section-title">操作记录</span>
          <el-button type="primary" size="small" @click="handleAddOperation">
            新增记录
          </el-button>
        </div>
        
        <div class="operation-log-content">
          <el-table
            :data="displayedOperationLogs"
            style="width: 100%">
            <el-table-column
              prop="createTime"
              label="操作时间"
              width="180">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column
              prop="operator"
              label="操作人"
              width="120">
            </el-table-column>
            <el-table-column
              prop="content"
              label="操作内容">
            </el-table-column>
            <el-table-column
              label="操作"
              width="150"
              fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handleEdit(scope.row)">
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  class="delete-btn"
                  @click="handleDelete(scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          
          <div class="pagination-container">
            <el-pagination
              @size-change="handleOperationLogsPageSizeChange"
              @current-change="handleOperationLogsPageChange"
              :current-page="operationLogsPage.currentPage"
              :page-sizes="[5, 10, 20, 30]"
              :page-size="operationLogsPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="operationLogsPage.total">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 新增操作记录对话框 -->
      <el-dialog
        title="新增操作记录"
        :visible.sync="operationDialogVisible"
        width="500px">
        <el-form :model="operationForm" ref="operationForm" :rules="operationRules">
          <el-form-item label="操作时间" prop="createTime">
            <span>{{ formatDateTime(operationForm.createTime) }}</span>
          </el-form-item>
          <el-form-item label="操作内容" prop="content">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入操作内容"
              v-model="operationForm.content">
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="operationDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitOperation">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑操作记录对话框 -->
      <el-dialog
        title="编辑操作记录"
        :visible.sync="editDialogVisible"
        width="500px">
        <el-form :model="editForm" ref="editForm" :rules="operationRules">
          <el-form-item label="操作时间">
            <span>{{ editForm.createTime }}</span>
          </el-form-item>
          <el-form-item label="操作内容" prop="content">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入操作内容"
              v-model="editForm.content">
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'CombinedDashboard',
    data() {
      return {
        loading: false,
        dateRange: [new Date(), new Date()],
        searchQuery: '',
        belongToQuery: '',
        belongToOptions: [],
        tableData: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一天',
              onClick(picker) {
                const date = new Date()
                picker.$emit('pick', [date, date])
              }
            },
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
                picker.$emit('pick', [start, end])
              }
            }
          ],
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
        pageSize: 10,
        currentPage: 1,
        total: 0,
        sortBy: 'date',
        sortOrder: 'descending',
        showAllTTColumns: false,
        showAllGoogleColumns: false,
        isAdmin: false,
        lastUpdateTime: '',
        summaryData: {
          totalSpeed: 0,
          totalIncome: 0,
          avgROI: '0',
          updateTime: '-'
        },
        seriesNameQuery: '',
        styleNameQuery: '',
        expandedRowKeys: new Set(),
        operationLogs: [],
        showAllLogs: false,
        operationDialogVisible: false,
        operationLogsPage: {
          pageSize: 5,
          currentPage: 1,
          total: 0
        },
        operationForm: {
          createTime: '',
          content: '',
          operator: ''
        },
        operationRules: {
          content: [
            { required: true, message: '请输入操作内容', trigger: 'blur' },
            { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
          ]
        },
        editDialogVisible: false,
        editForm: {
          id: '',
          createTime: '',
          content: ''
        },
        platform: ''
      }
    },
    computed: {
      filteredTableData() {
        return this.tableData
      },
      totalCost() {
        console.log('计算总花费，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const cost = Number(item.ttCost) || 0
          console.log('单项花费:', cost)
          return sum + cost
        }, 0)
        console.log('总花费计算结果:', total)
        return total
      },
      totalRevenue() {
        console.log('计算总收入，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const revenue = Number(item.googleRevenue) || 0
          console.log('单项收入:', revenue)
          return sum + revenue
        }, 0)
        console.log('总收入计算结果:', total)
        return total
      },
      averageRoi() {
        console.log('计算ROI，总费:', this.totalCost, '总收入:', this.totalRevenue)
        if (!this.totalCost) return 0
        const roi = this.totalRevenue / this.totalCost
        console.log('ROI计算结果:', roi)
        return roi
      },
      totalGroups() {
        return Array.isArray(this.tableData) ? this.tableData.length : 0
      },
      displayedOperationLogs() {
        return this.operationLogs
      },
      isKwai() {
        return this.getPlatform() === 'kwai'
      },
      isFb() {
        return this.getPlatform() === 'fb'
      }
    },
    methods: {
      // 获取当前平台类型
      getPlatform() {
        const platform = this.$route.query.platform
        if (platform === 'fb') {
          return 'fb'
        } else if (platform === 'kwai') {
          return 'kwai'
        } else {
          return 'tt'
        }
      },

      // 获取对应平台的API路径
      getApiPath(type) {
        const platform = this.getPlatform()
        const baseUrl = '/launch/data/dash/board'

        // API 路径映射
        const apiMap = {
          list: 'list',
          total: 'total',
          listDataDashBoardStatisticDetail: 'listDataDashBoardStatisticDetail',
          listDataDashBoardOperationRecord: 'listDataDashBoardOperationRecord',
          saveDataDashBoardOperationRecord: 'saveDataDashBoardOperationRecord',
          updateDataDashBoardOperationRecord: 'updateDataDashBoardOperationRecord',
          deleteDataDashBoardOperationRecord: 'deleteDataDashBoardOperationRecord'
        }

        // 获取正确的 API 路径
        const apiPath = apiMap[type] || type

        return platform === 'tt' ?
          `${baseUrl}/${apiPath}` :
          `${baseUrl}/${platform}/${apiPath}`
      },

      async fetchData() {
        if (!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) return

        this.loading = true
        const params = {
          startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dateRange[1]).format('YYYY-MM-DD'),
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          sid: this.$route.params.sid,
          seriesName: this.seriesNameQuery,
          styleName: this.styleNameQuery,
          sortBy: "statTimeDay",
          sortOrder: "desc"
        }

        if (this.isAdmin) {
          params.belongTo = this.belongToQuery.join(',')
        }

        const platform = this.getPlatform()

        let stack = [
          this.$http.post(this.getApiPath('list'), params),
          this.$http.post(this.getApiPath('total'), {
            startDate: params.startDate,
            endDate: params.endDate,
            sid: params.sid,
            belongTo: params.belongTo,
            seriesName: this.seriesNameQuery,
            styleName: this.styleNameQuery,
          })
        ]

        try {
          const [listResponse, totalResponse] = await Promise.all(stack)

          // 处理列表数据
          const responseData = listResponse.data.body?.data || []
          this.total = listResponse.data.body?.total || 0

          // 根据平台类型处理数据
          this.tableData = responseData.map(item => {
            const platform = this.getPlatform()
            const baseInfo = {
              seriesName: item.seriesName || '',
              sid: item.sid || '',
              styleName: item.styleName || '',
              date: item.statTimeDay || '',
              belongTo: item.belongTo || '',
            }

            if (platform === 'fb') {
              const fbResponse = item.faceBookResponse || {}
              return {
                ...baseInfo,
                // FB投放数据
                ttBudget: Number(fbResponse.budget || 0),
                ttCost: Number(fbResponse.spend || 0),
                ttImpressions: Number(fbResponse.displayCount || 0),
                ttClicks: Number(fbResponse.clickCount || 0),
                ttConversions: Number(fbResponse.resultCount || 0),
                ttCpm: Number(fbResponse.thousandDisplaySpend || 0),
                ttCpc: Number(fbResponse.costPerClick || 0),
                ttCpa: Number(fbResponse.costPerResult || 0),
                ttCtr: Number(fbResponse.materialClickRate || 0),
                ttCvr: Number(fbResponse.conversionRate || 0),
                // Google变现数据
                ...this.processAdsenseData(item.adsenseResponse),
                // 关联指标
                funnelShowRate: Number(item.funnelShowRate || 0),
                funnelClickRate: Number(item.funnelClickRate || 0),
                fbResult: item.fbResult || "",
                roi: Number(item.roi || 0),
                adsenseResult: item.adsenseResult || "",
                // 账户信息
                accountId: item.accountId || '',
                mainPart: item.mainPart || '',
                agent: item.agent || '',
                directCustomer: item.directCustomer || '',
              }
            } else if (platform === 'kwai') {
              const kwaiResponse = item.kWAIResponse || {}
              return {
                ...baseInfo,
                // Kwai没有投放数据，设置为0
                // Kwai投放数据
                kwaiCost: Number(kwaiResponse.spend || 0),
                kwaiImpressions: Number(kwaiResponse.displayCount || 0),
                kwaiClicks: Number(kwaiResponse.clickCount || 0),
                kwaiConversions: Number(kwaiResponse.transformCount || 0),
                kwaiCpm: Number(kwaiResponse.thousandDisplaySpend || 0),
                kwaiCpc: Number(kwaiResponse.clickSpend || 0),
                kwaiCpa: Number(kwaiResponse.avgTransformSpend || 0),
                kwaiCtr: Number(kwaiResponse.clickRate || 0),
                kwaiCvr: Number(kwaiResponse.transformRate || 0),
                // Google变现数据
                ...this.processAdsenseData(item.adsenseResponse),
                // 关联指标
                funnelShowRate: Number(item.funnelShowRate || 0),
                funnelClickRate: Number(item.funnelClickRate || 0),
                roi: item.roi,
                KWAIResult: item.kWAIResult || "",
                adsenseResult: item.adsenseResult || "",
                // 账户信息
                accountId: item.accountId || '',
                mainPart: item.mainPart || '',
                agent: item.agent || '',
              }
            } else {
              // TT平台数据处理
              const ttResponse = item.tikTokResponse || {}
              return {
                ...baseInfo,
                // TT投放数据
                ttCost: Number(ttResponse.spend || 0),
                ttImpressions: Number(ttResponse.displayCount || 0),
                ttClicks: Number(ttResponse.clickCount || 0),
                ttConversions: Number(ttResponse.transformCount || 0),
                ttCpm: Number(ttResponse.thousandDisplaySpend || 0),
                ttCpc: Number(ttResponse.clickSpend || 0),
                ttCpa: Number(ttResponse.avgTransformSpend || 0),
                ttCtr: Number(ttResponse.clickRate || 0),
                ttCvr: Number(ttResponse.transformRate || 0),
                // Google变现数据
                ...this.processAdsenseData(item.adsenseResponse),
                // 关联指标
                roi: Number(item.roi || 0),
                funnelShowRate: Number(item.funnelShowRate || 0),
                funnelClickRate: Number(item.funnelClickRate || 0),
                ttResult: item.ttResult || "",
                adsenseResult: item.adsenseResult || "",
                // 账户信息
                accountId: item.accountId || '',
                mainPart: item.mainPart || '',
                agent: item.agent || '',
                directCustomer: item.directCustomer || '',
              }
            }
          })

          if (platform === 'kwai') {
            return;
          }
          // 处理汇总数据
          if (totalResponse.data.header.code === 0 && totalResponse.data.body?.data) {
            const { updateTime, totalSpeed, totalIncome, avgROI } = totalResponse.data.body.data
            this.summaryData = {
              totalSpeed: Number(totalSpeed || 0),
              totalIncome: Number(totalIncome || 0),
              avgROI: avgROI || '0',
              updateTime: updateTime || '-'
            }
          }
        } catch (error) {
          console.error('获取数据失败:', error)
          this.$message.error('获取数据失败：' + error.message)
          this.resetData()
        } finally {
          this.loading = false
        }
      },

      // 处理 Adsense 数据的辅助方法
      processAdsenseData(adsenseResponse = {}) {
        return {
          googleRevenue: Number(adsenseResponse.income || 0),
          googleFunnelRequests: Number(adsenseResponse.funnelRequestCount || 0),
          googleFunnelImpressions: Number(adsenseResponse.funnelShowCount || 0),
          googleFunnelClicks: Number(adsenseResponse.funnelClickCount || 0),
          googleFunnelEcpm: Number(adsenseResponse.funnelECPM || 0),
          googleRequests: Number(adsenseResponse.requestCount || 0),
          googleImpressions: Number(adsenseResponse.showCount || 0),
          googleCoverage: Number(adsenseResponse.coverage || 0),
          googleClicks: Number(adsenseResponse.clickCount || 0),
          googleClickRate: Number(adsenseResponse.clickRate || 0),
          googleCpc: Number(adsenseResponse.perClickCost || 0),
          googleEcpm: Number(adsenseResponse.ecpm || 0)
        }
      },

      // 重置数据的辅助方法
      resetData() {
        this.tableData = []
        this.total = 0
        this.summaryData = {
          totalSpeed: 0,
          totalIncome: 0,
          avgROI: '0',
          updateTime: '-'
        }
      },

      async handleDateChange() {
        this.currentPage = 1; // 重置页码到第一页
        await this.fetchData();
      },
      formatPercentage(value) {
        if (typeof value !== 'number') return '0%'
        // 检查是否为整数
        if (Math.floor(value) === value) {
          return `${value}%`
        }
        return `${value.toFixed(2)}%`
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.currentPage = 1
        this.fetchData()
      },
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
      },
      handleSortChange({ prop, order }) {
        this.sortBy = prop
        this.sortOrder = order

        // 本地排序
        if (order) {
          this.tableData.sort((a, b) => {
            let aValue = a[prop]
            let bValue = b[prop]

            // 特殊处理日期字段
            if (prop === 'date') {
              aValue = new Date(aValue).getTime()
              bValue = new Date(bValue).getTime()
            }

            // 特殊处理数字字段
            if (['ttCost', 'ttImpressions', 'ttClicks', 'ttConversions', 'ttCpm',
              'ttCpc', 'ttCpa', 'ttCtr', 'ttCvr', 'googleRevenue', 'roi'].includes(prop)) {
              aValue = parseFloat(aValue) || 0
              bValue = parseFloat(bValue) || 0
            }

            if (order === 'ascending') {
              return aValue > bValue ? 1 : -1
            } else {
              return aValue < bValue ? 1 : -1
            }
          })
        }
      },
      toggleTTColumns() {
        this.showAllTTColumns = !this.showAllTTColumns
      },
      toggleGoogleColumns() {
        this.showAllGoogleColumns = !this.showAllGoogleColumns
      },
      async handleSearch() {
        this.currentPage = 1
        await this.fetchData()
      },
      getRoiClass(roi) {
        const value = parseFloat(roi);
        if (value < 40) return 'roi-blue';
        if (value < 90) {
          if (value < 65) return 'roi-orange';  // 40-65 显示橙色
          return 'roi-yellow';  // 65-90 显示黄色
        }
        if (value < 150) return 'roi-light-green'; // 90-150 显示浅绿色
        return 'roi-green'; // 150以上显示深绿色
      },
      // 添加检查用户权限的方法
      async checkUserPermission() {
        try {
          const { data } = await this.$http.get('/launch/user/info')
          this.isAdmin = data.body.data.whetherAdmin === 1
        } catch (error) {
          console.error('获取用户权限失败:', error)
          this.isAdmin = false
        }
      },
      // 修改获取归属选项的方法
      async fetchBelongToOptions() {
        try {
          // 调用用户列表接口获取所有用户
          const { total } = await this.$store.dispatch('userManagement/fetchUsers', {
            pageNum: 1,
            pageSize: 999 // 获取足够大的数量以包含所有用户
          })

          // 从store中获取用户列表
          const users = this.$store.state.userManagement.users

          // 提取用户名作为归属选项
          this.belongToOptions = users.map(user => ({
            value: user.accountName,
            label: user.accountName
          }))
        } catch (error) {
          console.error('获取归属选项失败:', error)
          this.$message.error('获取归属选项失败：' + error.message)
        }
      },
      // 添加ROI排序方法
      sortROI(a, b) {
        // 移除百分号并转换为数字进行比较
        const roiA = parseFloat(a.roi) || 0;
        const roiB = parseFloat(b.roi) || 0;
        return roiA - roiB;
      },
      handleViewDetail(row) {
        this.$router.push({
          name: 'DataDetail',
          params: {
            sid: row.sid,
            seriesName: row.seriesName
          }
        })
      },
      async handleExpandChange(row, expandedRows) {
        const rowKey = this.getRowKey(row);

        if (expandedRows.includes(row)) {
          this.expandedRowKeys.add(rowKey)
          this.$set(row, 'hourlyData', [])
          this.$set(row, 'loading', true)

          try {
            const { data } = await this.$http.get(
              this.getApiPath('listDataDashBoardStatisticDetail'),
              {
                params: {
                  startDate: moment(row.date).format('YYYY-MM-DD'),
                  endDate: moment(row.date).format('YYYY-MM-DD'),
                  sid: row.sid
                }
              }
            )

            if (data.body?.data?.[0]?.dashBoardStatisticDetailClockResponse) {
              // 处理小时数据,统一数据格式
              const hourlyData = data.body.data[0].dashBoardStatisticDetailClockResponse.map(item => {
                if (this.getPlatform() === 'fb') {
                  return {
                    statTimeClock: item.statTimeClock,
                    ttSpend: Number(item.fbSpend || 0),
                    ttAvgTransformSpend: Number(item.fbAvgTransformSpend || 0),
                    adsenseIncome: Number(item.adsenseIncome || 0),
                    adsensePerClickCost: Number(item.adsensePerClickCost || 0),
                    adsenseECPM: Number(item.adsenseECPM || 0),
                    transformRate: Number(item.fbTransformRate || 0),
                    roi: item.roi
                  }
                } else if (this.getPlatform() === 'kwai') {
                  return {
                    statTimeClock: item.statTimeClock,
                    ttSpend: Number(item.kwaiSpend || 0),
                    ttAvgTransformSpend: Number(item.kwaiAvgTransformSpend || 0),
                    adsenseIncome: Number(item.adsenseIncome || 0),
                    adsensePerClickCost: Number(item.adsensePerClickCost || 0),
                    adsenseECPM: Number(item.adsenseECPM || 0),
                    transformRate: Number(item.kwaiTransformRate || 0),
                    roi: item.roi
                  }
                } else {
                  // TT平台数据保持原样
                  return {
                    statTimeClock: item.statTimeClock,
                    ttSpend: Number(item.ttSpend || 0),
                    ttAvgTransformSpend: Number(item.ttAvgTransformSpend || 0),
                    adsenseIncome: Number(item.adsenseIncome || 0),
                    adsensePerClickCost: Number(item.adsensePerClickCost || 0),
                    adsenseECPM: Number(item.adsenseECPM || 0),
                    transformRate: Number(item.ttTransformRate || 0),
                    roi: item.roi
                  }
                }
              })
              this.$set(row, 'hourlyData', hourlyData)
            }
          } catch (error) {
            console.error('获取小时数据失败:', error)
            this.$message.error('获取小时数据失败：' + error.message)
          } finally {
            this.$set(row, 'loading', false)
          }
        } else {
          this.expandedRowKeys.delete(rowKey)
        }
      },
      getRowKey(row) {
        return `${row.sid}-${row.date}`; // 使用 sid 和 date 组合作为唯一标识符
      },
      async fetchOperationLogs() {
        try {
          const params = {
            startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
            endDate: moment(this.dateRange[1]).format('YYYY-MM-DD'),
            sid: this.$route.params.sid,
            pageNum: this.operationLogsPage.currentPage,
            pageSize: this.operationLogsPage.pageSize
          }

          const { data } = await this.$http.get(
            this.getApiPath('listDataDashBoardOperationRecord'),
            { params }
          )

          if (data.header.code === 0) {
            this.operationLogs = (data.body.data || []).map(item => ({
              id: item.id,
              createTime: item.updateTime,
              operator: item.accountName,
              content: item.description
            }))
            this.operationLogsPage.total = data.body.total || 0
          }
        } catch (error) {
          console.error('获取操作记录失败:', error)
          this.$message.error('获取操作记录失败：' + error.message)
        }
      },
      handleAddOperation() {
        this.operationForm = {
          createTime: new Date().getTime(),
          content: '',
          operator: this.$store.state.user.name || ''
        }
        this.operationDialogVisible = true
      },
      async submitOperation() {
        this.$refs.operationForm.validate(async (valid) => {
          if (valid) {
            try {
              const params = {
                sid: this.$route.params.sid,
                description: encodeURIComponent(this.operationForm.content)
              }
              const queryString = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&')

              const { data } = await this.$http.post(
                `${this.getApiPath('saveDataDashBoardOperationRecord')}?${queryString}`
              )

              if (data.header.code === 0) {
                this.$message.success('添加操作记录成功')
                this.operationDialogVisible = false
                await this.fetchOperationLogs()
              }
            } catch (error) {
              console.error('添加操作记录失败:', error)
              this.$message.error('添加操作记录失败：' + error.message)
            }
          }
        })
      },
      formatDateTime(timestamp) {
        return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
      },
      handleOperationLogsPageChange(page) {
        this.operationLogsPage.currentPage = page
        this.fetchOperationLogs()
      },
      handleOperationLogsPageSizeChange(size) {
        this.operationLogsPage.pageSize = size
        this.operationLogsPage.currentPage = 1
        this.fetchOperationLogs()
      },
      handleEdit(row) {
        this.editForm = {
          id: row.id,
          createTime: row.createTime,
          content: row.content
        }
        this.editDialogVisible = true
      },
      async submitEdit() {
        this.$refs.editForm.validate(async (valid) => {
          if (valid) {
            try {
              const params = {
                id: this.editForm.id,
                description: encodeURIComponent(this.editForm.content)
              }
              const queryString = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&')

              const { data } = await this.$http.post(
                `${this.getApiPath('updateDataDashBoardOperationRecord')}?${queryString}`
              )

              if (data.header.code === 0) {
                this.$message.success('修改操作记录成功')
                this.editDialogVisible = false
                await this.fetchOperationLogs()
              }
            } catch (error) {
              console.error('修改操作记录失败:', error)
              this.$message.error('修改操作记录失败：' + error.message)
            }
          }
        })
      },
      async handleDelete(row) {
        this.$confirm('确认删除该操作记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            const { data } = await this.$http.post(
              `${this.getApiPath('deleteDataDashBoardOperationRecord')}?id=${row.id}`
            )

            if (data.header.code === 0) {
              this.$message.success('删除操作记录成功')
              await this.fetchOperationLogs()
            }
          } catch (error) {
            console.error('删除操作记录失败:', error)
            this.$message.error('删除操作记录失败：' + error.message)
          }
        }).catch(() => {
          // 取消删除操作
        })
      },
      // 添加一个计算汇总数据的方法
      calculateSummary() {
        let totalSpend = 0
        let totalIncome = 0

        this.tableData.forEach(day => {
          totalSpend += day.dailyTotalSpend
          totalIncome += day.dailyTotalIncome
        })

        const avgROI = totalSpend > 0 ? ((totalIncome / totalSpend) * 100).toFixed(2) : '0'

        this.summaryData = {
          totalSpeed: totalSpend,
          totalIncome: totalIncome,
          avgROI: avgROI,
          updateTime: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      },
      // 添加行类名方法
      tableRowClassName({ row }) {
        const platform = this.getPlatform()
        if (platform === 'fb') {
          if (row.adsenseResult === 0 || row.fbResult === 0) {
            return 'error-row'
          }
        } else if (platform === 'kwai') {
          if (row.adsenseResult === 0 || row.KWAIResult === 0) {
            return 'error-row'
          }
        } else {
          if (row.adsenseResult === 0 || row.ttResult === 0) {
            return 'error-row'
          }
        }
        return ''
      },
    },
    watch: {
      dateRange: {
        handler(newVal) {
          if (newVal && newVal[0] && newVal[1]) {
          }
        },
        immediate: false
      }
    },
    created() {
      // 设置默认日期范围为最近15天
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
      this.dateRange = [start, end]

      // 只在创建时调用一次 fetchData 和 fetchOperationLogs
      this.fetchData()
      this.fetchOperationLogs()

      // 初始化时设置默认排序
      this.handleSortChange({
        prop: 'date',
        order: 'descending'
      })
    }
  }
</script>

<style scoped>
  .combined-dashboard {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;
  }

  .bold {
    font-weight: bolder;
  }

  /* 主卡片样式 */
  .combined-dashboard> :deep(.el-card) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.el-card__body) {
    flex: 1;
    padding: 15px;
    min-height: 0;
  }

  /* 头部样式 */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-controls {
    display: flex;
    align-items: center;
  }

  .header-controls .el-button {
    margin-left: 0;
  }

  /* 表格相关样式 */
  .series-name {
    display: inline-block;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :deep(.el-table) {
    height: auto;
  }

  :deep(.el-table th .cell) {
    display: flex;
    align-items: center;
  }

  /* 分页样式 */
  .pagination-container {
    margin: 20px 0;
    text-align: right;
  }

  /* 汇总部分样式优化 */
  .summary-section {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .summary-item {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #f0f0f0;
  }

  .summary-item.update-time {
    border-right: none;
    justify-content: space-between;
  }

  .summary-item.update-time .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-item.update-time .summary-value.time {
    font-size: 12px;
    color: #8c8c8c;
  }

  .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-value {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }

  .summary-value.cost {
    color: #ff4d4f;
  }

  .summary-value.revenue {
    color: #52c41a;
  }

  .summary-value.roi {
    color: #1890ff;
  }

  .summary-value.count {
    color: #722ed1;
  }

  /* 表格样式优化 */
  :deep(.el-table) {
    margin-top: 15px;
  }

  :deep(.el-table th.el-table__cell) {
    background-color: #fafafa;
    font-weight: 600;
    padding: 4px 0;
    height: 36px;
  }

  :deep(.el-table--border th.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table--border td.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table__header .cell) {
    line-height: 28px;
    height: 28px;
  }

  :deep(.el-table__header .cell .el-button) {
    padding: 0 3px;
    height: 28px;
    line-height: 28px;
  }

  /* 分页样式优化 */
  .pagination-container {
    margin: 16px 0 0;
    padding-top: 16px;
    border-top: 1px solid #f0f0f0;
  }

  /* 其他工具样式 */
  .el-date-editor.el-input {
    width: 220px;
  }

  .el-button [class^="el-icon-"] {
    margin-left: 0;
  }

  /* 滚动条样式 */
  :deep(.el-card__body::-webkit-scrollbar) {
    width: 6px;
  }

  :deep(.el-card__body::-webkit-scrollbar-thumb) {
    background: #dcdfe6;
    border-radius: 3px;
  }

  :deep(.el-card__body::-webkit-scrollbar-track) {
    background: #f5f7fa;
  }

  :deep(.highlight-column) {
    background-color: #f0f5ff !important;
  }

  :deep(.el-table__row:hover .highlight-column) {
    background-color: #f0f5ff !important;
  }

  .roi-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 100%;
  }

  .roi-blue {
    background-color: #f0f5ff;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-orange {
    background-color: #ffccc7;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-yellow {
    background-color: #ffd666;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-light-green {
    background-color: #b7eb8f;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-green {
    background-color: #52c41a;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  /* 为所有表格单元格添加内边距 */
  :deep(.el-table td.el-table__cell) {
    padding: 0;
    height: 40px;
  }

  :deep(.el-table .cell) {
    padding: 0 5px;
    /* 添加左右5px内边距 */
    height: 100%;
    display: flex;
    /* 使用flex布局实现水平垂直居中 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
  }

  /* ROI列保持无内边距 */
  :deep(.el-table td.el-table__cell:has(.roi-cell) .cell) {
    padding: 0;
  }

  /* 对于需要左对齐的列（如系列名称等），可以单独设置 */
  :deep(.el-table td.el-table__cell .cell .series-name) {
    justify-content: flex-start;
  }

  /* 确保悬停状态保持背景色 */
  :deep(.el-table__row:hover .roi-blue) {
    background-color: #f0f5ff;
  }

  :deep(.el-table__row:hover .roi-orange) {
    background-color: #ffccc7;
  }

  :deep(.el-table__row:hover .roi-yellow) {
    background-color: #ffd666;
  }

  :deep(.el-table__row:hover .roi-light-green) {
    background-color: #b7eb8f;
  }

  :deep(.el-table__row:hover .roi-green) {
    background-color: #52c41a;
  }

  /* 添加下拉框样式 */
  :deep(.el-select) {
    width: 200px;
  }

  :deep(.el-select .el-select__tags) {
    max-width: calc(100% - 30px);
  }

  :deep(.el-input) {
    width: 220px;
  }

  /* 添加展开面板的样式 */
  .expand-detail {
    margin-left: 567px;
    background: #fafafa;
  }

  :deep(.expand-detail .el-table) {
    font-size: 12px;
  }

  :deep(.expand-detail .el-table td) {
    padding: 4px 0;
  }

  :deep(.expand-detail .el-table .cell) {
    padding: 0 4px;
    line-height: 20px;
  }

  .number-cell {
    display: inline-block;
    text-align: right;
    width: 100%;
  }

  /* 确保展开行内容紧凑 */

  /* 优化展开图标样式 */
  :deep(.el-table__expand-icon) {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  /* 新增的style */
  .operation-log-section {
    margin-top: 30px;
    background: #fff;
    border-radius: 4px;
    padding: 0 15px 15px;
    box-sizing: border-box;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    position: relative;
  }

  .operation-log-header {
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section-title {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding-left: 10px;
  }

  .section-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 16px;
    background-color: #409EFF;
    border-radius: 2px;
  }

  .operation-log-list {
    position: relative;
    background: #fafafa;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  }

  .operation-log-list .pagination-container {
    margin-top: 15px;
    text-align: right;
    padding-right: 15px;
  }

  .delete-btn {
    color: #f56c6c;
  }

  .delete-btn:hover {
    color: #ff4d4f;
  }

  /* 表格样式优化 */
  :deep(.operation-log-list .el-table) {
    border-radius: 4px;
    overflow: hidden;
  }

  :deep(.operation-log-list .el-table th) {
    background-color: #f5f7fa;
  }

  /* 展开详情表格样式优化 */
  .expand-detail {
    background: #fafafa;
  }

  :deep(.expand-detail .el-table) {
    font-size: 12px;
  }

  :deep(.expand-detail .el-table td) {
    padding: 4px 0;
  }

  :deep(.expand-detail .el-table .cell) {
    padding: 0 8px;
    line-height: 20px;
    white-space: nowrap;
  }

  .number-cell {
    display: inline-block;
    width: auto;
    min-width: 60px;
  }


  /* 优化展开图标样式 */
  :deep(.el-table__expand-icon) {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  /* 表格列自适应宽度 */
  :deep(.el-table__header-wrapper),
  :deep(.el-table__body-wrapper) {
    overflow-x: auto !important;
  }

  :deep(.el-table__body) td {
    white-space: nowrap;
  }

  :deep(.el-table) {
    width: auto !important;
    min-width: 100%;
  }

  /* 修改主表格单元格高度 */
  :deep(.el-table:not(.expand-detail .el-table) td.el-table__cell) {
    padding: 0;
    height: 30px;
  }

  /* 展开表格单元格样式 */
  :deep(.expand-detail .el-table td.el-table__cell) {
    padding: 0;
    height: auto;
    min-height: 24px;
  }

  /* 展开表格内容样式优化 */
  :deep(.expand-detail .el-table .cell) {
    padding: 4px;
    line-height: 1.2;
    min-height: 24px;
  }

  /* 修改错误行样式 */
  :deep(.error-row) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover > td) {
    background-color: #fde2e2 !important;
  }

  /* 确保高亮列在错误行中也显示错误背景色 */
  :deep(.error-row .highlight-column) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover .highlight-column) {
    background-color: #fde2e2 !important;
  }

  /* 确保ROI列在错误行中也显示错误背景色 */
  :deep(.error-row .roi-cell) {
    background-color: #fef0f0 !important;
  }

  :deep(.error-row:hover .roi-cell) {
    background-color: #fde2e2 !important;
  }

  /* 覆盖所有ROI状态类在错误行中的样式 */
  :deep(.error-row .roi-blue),
  :deep(.error-row .roi-orange),
  :deep(.error-row .roi-yellow),
  :deep(.error-row .roi-light-green),
  :deep(.error-row .roi-green) {
    background-color: #fef0f0 !important;
    color: #333 !important;
  }

  :deep(.error-row:hover .roi-blue),
  :deep(.error-row:hover .roi-orange),
  :deep(.error-row:hover .roi-yellow),
  :deep(.error-row:hover .roi-light-green),
  :deep(.error-row:hover .roi-green) {
    background-color: #fde2e2 !important;
    color: #333 !important;
  }
</style> 