<template>
  <div class="iframe-container">
    <iframe 
      src="https://ggapi.hippofunnygame.com/iframe/index.html?type=1#/tt-data-location"
      frameborder="0"
      width="100%"
      height="100%"
      style="min-height: calc(100vh - 60px);"
    ></iframe>
  </div>
</template>

<script>
    export default {
        name: 'DataLocations'
    }
</script>

<style scoped>
    .iframe-container {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
</style> 